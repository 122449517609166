import { SETTING_ACTION } from './../../../../store/actions/settings.action';
import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';
import { Component, OnInit } from '@angular/core';
import { TenantSettingsService } from 'app/core/services/componentServices/tenant-settings.service';
import { AlertService } from 'app/shared/services/misc';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { selectTenantID } from 'app/store/selectors/shared.selector';
import { filter } from 'rxjs/operators';
import { CustomerListService } from 'app/shared/services/comp';
import { AVAILABLE_DASHBOARD_ACTION } from 'app/store/actions/availableDashboard.actions';

@Component({
    selector: 'tenant-settings',
    templateUrl: './tenant-settings.component.html',
    styleUrls: ['./tenant-settings.component.scss'],
})
export class TenantSettingsComponent implements OnInit {

    dashboardSettings = [
        {
            name: 'Commercial View',
            description:
                'Commercial view will give you the functionalitty to add an amount for each interface and make it easy to track internet expenses ',
            enabled: false,
            key: 'commercialDashboard',
            customizable: false,
        },
        {
            name: 'Usage Graph',
            description: 'Usage Graph shows the realtime traffic in all the interfaces which are online',
            enabled: false,
            key: 'netflow',
            customizable: false,
        },
        {
            name: 'VPN Status',
            description: 'VPN Status shows the current VPN status',
            enabled: false,
            key: 'vpnStatus',
            customizable: false,
        },
        {
            name: 'WAN 3',
            description: 'WAN 3 shows the status of wan 3. NOTE: only enable if you have configured WAN 3',
            enabled: false,
            key: 'wan3',
            customizable: false,
        },
        {
            name: 'VPN Dashboard',
            description: 'Show VPN Dashboard',
            enabled: false,
            key: 'vpnDashboard',
            customizable: false,
        },
        {
            name: 'Auto Registeration',
            description: 'Device auto registeration',
            enabled: true,
            key: 'devReg_autoApprove',
            customizable: false,
            hide: true
        },
        // {
        //   name: 'Pagination',
        //   description: 'adds pagination to dashboard status table',
        //   enabled: false,
        //   key: 'pagination',
        //   customizable: false,
        //   pageSize: 10
        // }
    ];
    tenantId;

    constructor(
        private tenantSettings: TenantSettingsService,
        private alertService: AlertService,
        private customerListService: CustomerListService,
        private store: Store<CosgridAppStore>,
    ) { }

    ngOnInit() {
        this.tenantId = this.customerListService.getTenantId();
        if (this.tenantId) {
            this.getSettings();
        }
        this.store
            .select(selectTenantID)
            .pipe(filter((tid) => !!tid))
            .subscribe((tid) => {
                this.tenantId = tid;

                this.getSettings();
            });
    }

    getSettings() {
        this.tenantSettings.getTenantSettings(this.tenantId).subscribe((res: any) => {
            this.dashboardSettings = this.dashboardSettings.map((setting) => {
                return {
                    ...setting,
                    enabled: !!res.dashboard[setting.key],
                };
            });
            this.dashboardSettings[this.dashboardSettings.length - 1].enabled = !!res.devReg_autoApprove
        });
    }

    saveSettings() {
        const dashboardSettings: any = this.dashboardSettings.reduce((acc, setting) => {
            acc[setting.key] = setting.enabled;
            return acc;
        }, {});

        this.store.dispatch({
            type: SETTING_ACTION,
            payload: dashboardSettings,
        });

        if (dashboardSettings.vpnDashboard == true) {
            this.store.dispatch({
                type: AVAILABLE_DASHBOARD_ACTION,
                payload: {
                    dashboardFromPreference: false,
                    currentDashboard: 'Zero Trust',
                    dashboardIcon: 'mza-logo'
                }
            })
        } else {
            this.store.dispatch({
                type: AVAILABLE_DASHBOARD_ACTION,
                payload: {
                    dashboardFromPreference: false,
                    currentDashboard: 'SD Wan',
                    dashboardIcon: 'sdwan-logo'
                }
            })
        }


        const data = {
            dashboard: dashboardSettings,
            devReg_autoApprove: dashboardSettings.devReg_autoApprove
        }

        delete data.dashboard.devReg_autoApprove

        console.log(data);


        this.tenantSettings.saveTenantSettings(data).subscribe(
            (res) => {
                this.alertService.swalSuccess('Settings saved successfully');
            },
            (err) => {
                // to revoke to old settinsgs
                setTimeout(() => {
                    this.getSettings();
                }, 500);
            },
        );
    }
}
