<div class="chartConatiner row" >

<div class="client-server col-6">
    <div *ngIf="loading1" class="loaderRound "> </div>
<div>
    <select style="float: right;margin-right: 15px;margin-bottom: 10px;" (change)="changeChart1()" [(ngModel)]="graphConfData1.timeduration" class="form-control form-control-sm col-2" >
        <option value=30 >30 m</option>
        <option value=60 >1 hr</option>
        <option value=180 >3 hr</option>
        <option value=720 >12 hr</option>
        <option value=1440 >24 hr</option>
    </select>
</div>
<div *ngIf="isEmpty1" style="margin: auto; height: 12vh;">
    <p style="margin: 5vh !important; height: 100%">
        No data available yet!
    </p>
</div>
<div *ngIf="!isEmpty1" style="height: 350px;width: 100%;">
    <canvas id="tenantGraph1"></canvas>
    <!-- <cosgrid-user-level-graphs></cosgrid-user-level-graphs> -->
</div>
</div>

  <div class="client-server col-5">
  <div *ngIf="loading" class="loaderRound "> </div>
<div >
  <select  style="float: right;" (change)="changeChart()" [(ngModel)]="graphConfData.ip_addr" class="form-control form-control-sm col-3" >
                   <option default value=""> Select IP</option>
                    <option *ngFor="let item of userIps" [value]="item"  >{{item}}</option>
                  </select>
    <select style="float: right;margin: 0 10px" (change)="changeChart()" [(ngModel)]="currentChartId" class="form-control form-control-sm col-3" >
        <option value="1" >Top Applications</option>
        <option value="2" >Top Protocols</option>
        <option value="3" >Top Ports</option>
    </select>
  <select style="float: right;" (change)="changeChart()" [(ngModel)]="graphConfData.timeduration" class="form-control form-control-sm col-2" >
      <option value=30 >30 m</option>
      <option value=60 >1 hr</option>
      <option value=180 >3 hr</option>
      <option value=720 >12 hr</option>
      <option value=1440 >24 hr</option>
  </select>
</div>
<div *ngIf="isEmpty" style="margin: auto; height: 12vh;">
  <p style="margin: 5vh !important; height: 100%">
      No data available yet!
  </p>
</div>
<div *ngIf="!isEmpty" style="height: 360px;width: 100%;margin-top: 10px;">
  <canvas id="tenantGraph" style="width: 100%;height: 360px;"></canvas>
</div>


</div>
</div>
