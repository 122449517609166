import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'app/shared/services/core';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'timeformat'
})
export class TimeformatPipe implements PipeTransform {
  userTimeZone: string = ''
  constructor(
    private userService: UserService
  ) {

    this.userTimeZone = this.userService.getUser().timezone || Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
  }

  transform(utcDate: string, formatType: string = 'DD/MM/yyyy HH:mm:ss'): string {
    console.log(moment.utc(utcDate).tz(this.userTimeZone).format(formatType));

    return moment.utc(utcDate).tz(this.userTimeZone).format(formatType);
  }

}
