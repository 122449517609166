<!--<div class="wrapper">
  <h2 class="mr-2">Aggregate Performance Metrics</h2>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=6" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=16" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=17" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=18" width="350" height="200" frameborder="0"></iframe></div>
  </div>
</div>
<div class="wrapper">
  <h2 class="mr-2">Navigation Timing Metrics</h2>
  <div class="card">
    <iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=4"  height="300" frameborder="0"></iframe>
  </div>
</div>
<div class="wrapper">
  <h2 class="mr-2">Transaction Monitoring</h2>
  <div class="card">
  <iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=2" height="300"  frameborder="0"></iframe>
  </div>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=7" width="450" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=11" width="450" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=8" width="450" height="200" frameborder="0"></iframe></div>
  </div>
</div>
<div class="wrapper">
  <h2 class="mr-2">Essential Script Performance Metrics</h2>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=13" width="450" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=14" width="450" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/eX34qINMk/murugavels-dashboard?orgId=1&from=1598001300000&to=1598004000000&panelId=15" width="450" height="200" frameborder="0"></iframe></div>
  </div>
</div>-->


<div class="wrapper">
  <h2 class="mr-2">Recent Session Performance</h2>
  <table class="table table-striped" id="table">
    <thead>
        <tr>
            <th>Most Recent Login</th>
            <th>User</th>
            <th>IP</th> 
        </tr>          
    </thead>
    <tbody>
      <tr (click)="clickeditem(1)">
          <td>29-Aug-20, 07:54</td>
          <td>Murugavel</td>
          <td>162.158.165.236 </td>
      </tr>
      <tr (click)="clickeditem(2)">
          <td>29-Aug-20, 07:32</td>
          <td>Kartikey</td>
          <td>127.0.0.1</td>
      </tr>
      <tr (click)="clickeditem(3)">
          <td>29-Aug-20, 07:24</td>
          <td>Murugavel</td>
          <td>localhost</td>
      </tr>
    </tbody>
  </table>

</div>

<div *ngIf="checkcounter(1)" class="wrapper">
  <h2 class="mr-2">User: Murugavel</h2>
  <h2 class="mr-2">IP: 162.158.165.236</h2>
  <h2 class="mr-2">Session Metrics</h2>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/Fh_5UqHGk/127-0-0-1?orgId=1&from=1598653003659&to=1598674603659&panelId=2" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/Fh_5UqHGk/127-0-0-1?orgId=1&from=1598653128616&to=1598674728616&panelId=3" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/Fh_5UqHGk/127-0-0-1?orgId=1&from=1598653139469&to=1598674739469&panelId=4" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/Fh_5UqHGk/127-0-0-1?orgId=1&from=1598653161949&to=1598674761949&panelId=5" width="350" height="200" frameborder="0"></iframe></div>
  </div>
  <h2 class="mr-2">Navigation Timings</h2>
  <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/Fh_5UqHGk/127-0-0-1?orgId=1&from=1598653293309&to=1598674893310&panelId=7" height="300" frameborder="0"></iframe></div>
</div>

<div *ngIf="checkcounter(2)" class="wrapper">
  <h2 class="mr-2">User: Kartikey</h2>
  <h2 class="mr-2">IP: 127.0.0.1</h2>
  <h2 class="mr-2">Session Metrics</h2>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/A0aPUqHMk/162-158-165-236?orgId=1&from=1598653913658&to=1598675513658&panelId=2" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/A0aPUqHMk/162-158-165-236?orgId=1&from=1598653949761&to=1598675549761&panelId=3" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/A0aPUqHMk/162-158-165-236?orgId=1&from=1598653961266&to=1598675561267&panelId=4" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/A0aPUqHMk/162-158-165-236?orgId=1&from=1598653973960&to=1598675573961&panelId=5" width="350" height="200" frameborder="0"></iframe></div>
  </div>
  <h2 class="mr-2">Navigation Timings</h2>
  <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/A0aPUqHMk/162-158-165-236?orgId=1&from=1598653988709&to=1598675588709&panelId=7"  height="300" frameborder="0"></iframe></div>
</div>

<div *ngIf="checkcounter(3)" class="wrapper">
  <h2 class="mr-2">User: Murugavel</h2>
  <h2 class="mr-2">IP: localhost</h2>
  <h2 class="mr-2">Session Metrics</h2>
  <div style="display: flex;">
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/b22h83NGk/localhost?orgId=1&from=1598654019559&to=1598675619559&panelId=2" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/b22h83NGk/localhost?orgId=1&from=1598654031530&to=1598675631530&panelId=3" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/b22h83NGk/localhost?orgId=1&from=1598654044682&to=1598675644683&panelId=4" width="350" height="200" frameborder="0"></iframe></div>
    <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/b22h83NGk/localhost?orgId=1&from=1598654057230&to=1598675657230&panelId=5" width="350" height="200" frameborder="0"></iframe></div>
  </div>
  <h2 class="mr-2">Navigation Timings</h2>
  <div  class="card"><iframe src="http://15.207.116.241:3003/d-solo/b22h83NGk/localhost?orgId=1&from=1598654073509&to=1598675673509&panelId=7" height="300" frameborder="0"></iframe></div>
</div>