<div class="table-wrapper">
  <div *ngIf="heading" class="heading">
    <h3>{{ heading }}</h3>
  </div>
  <cosgrid-overlay-loader [show]="refreshLoading"></cosgrid-overlay-loader>
  <span *ngIf="!refreshLoading && tableService.hasData(rows)">
    <div class="actions" [ngClass]="{'justify-content-end': !tableProps.search}">
      <div *ngIf="tableProps.search" class="searchbar">
        <i class="fas fa-search"></i>
        <input [(ngModel)]="searchText" (keyup)="searchRows()" placeholder="Search" />
      </div>

      <div class="action-buttons">
        <button *ngIf="tableProps.refresh" (click)="refreshRowData()" class="btn btn-light mr-2 border">
          <i *ngIf="refreshLoading" class="fas fa-sync fa-spin"></i>
          <i *ngIf="!refreshLoading" class="fas fa-sync"></i>
          Refresh
        </button>

        <button *ngIf="tableProps.download" (click)="downloadAllRows()" class="btn btn-light mr-2 border">
          <i class="fas fa-cloud-download-alt"></i>
          Download
        </button>
      </div>
    </div>

    <div class="main" cdkDropListGroup>
      <table cdkDropList class="table table-striped" [id]="uTableId" (cdkDropListDropped)="drop($event)">
        <thead>
          <tr>
            <th *ngIf="needMultiSelect"><input type="checkbox" value="-1" (click)="multiSelect($event)"></th>
            <!-- Empty th for checkbox -->
            <th *ngIf="needSerialNumbers" class="text-center">
              S.No
            </th>

            <th *ngIf="needRowClick"></th>
            <th *ngFor="let rowProp of rowProps; let i = index">
              <!-- <th *ngIf="multiSelectNeeded && i == 0">
              <input type="checkbox" (click)="selectAllRow($event, rows)">
            </th> -->
              <div class="d-flex"
                [ngClass]="{'justify-content-start': rowProp.headAlign === 'left', 'justify-content-center' : rowProp.headAlign === 'center', 'justify-content-end': rowProp.headAlign === 'right' }">
                {{ rowProp.header }}
                <span *ngIf="tableProps.sort" class="sortContainer">
                  <mat-icon (click)="sortRows(rowProp, 'asc', $event)" svgIcon="upArrow"
                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                  <mat-icon (click)="sortRows(rowProp, 'dsc', $event)" svgIcon="downArrow"
                    style="height: 12px;width: 8px;cursor: pointer;"></mat-icon>
                </span>
              </div>
              <!-- <th *ngFor="let header of dropdownHeaders" class="text-center">{{header}}</th> -->
            <th *ngIf="needStatus || needStatusBoolean">Status</th>
            <th *ngIf="needStatusCheckbox" class="text-center">{{statusBoxHeader}}</th>
            <th *ngIf="isConfig">Actions</th>
            <th *ngIf="needButton" class="text-center">Modify</th>
          </tr>
        </thead>

        <tbody>

          <tr *ngFor="let row of rows;let i=index" (click)="rowClick(row, $event)">
            <th *ngIf="needMultiSelect"><input type="checkbox" [value]="i" [checked]="selectedRows[i]"
                (change)="multiSelect($event)"></th>
            <td class="check" *ngIf="needRowClick">
              <input class="checkbox" type="checkbox" />
            </td>
            <td *ngIf="multiSelectNeeded">
              <input type="checkbox" [(ngModel)]="row.select" (change)="selectRow($event, row)">
            </td>
            <td *ngIf="needSerialNumbers" class="text-center align-middle">
              {{i+1}}
            </td>
            <td *ngFor="let rowProp of rowProps" [ngStyle]="rowProp.style" [ngClass]="{
              'text-start': rowProp.rowAlign === 'left' || rowProp.headAlign === 'left',
              'text-center': rowProp.rowAlign === 'center' || rowProp.headAlign === 'center',
              'text-end': rowProp.rowAlign === 'right' || rowProp.headAlign === 'right',
              'align-middle': rowProp.alignMiddle == 'true'
            }">
              <div class="d-flex">
                <span [ngSwitch]="rowProp.type">
                  <!-- <span *ngSwitchCase="'checkbox'">
              <input class="checkbox" type="checkbox" [value]="row.select" (change)="onCheckboxChange($event, row)"/>
            </span> -->
                  <span *ngSwitchCase="'string'" [ngStyle]="getDataStyle(row, rowProp)"
                    [innerHTML]="formatValue(row,rowProp) | emptyFiller: rowProp.nullMessage">
                    <!-- {{
                    formatValue(row,rowProp)
                    | emptyFiller: rowProp.nullMessage
                    }} -->
                  </span>
                  <span *ngSwitchCase="'icon'">
                    <cosgrid-table-icon [icon]="row['temp' + rowProp.key]"
                      [tooltip]="getValueModel(row, rowProp)"></cosgrid-table-icon>
                  </span>
                  <span *ngSwitchCase="'array'">
                    <span *ngIf="
                      tableService.hasData(row[rowProp.key]);
                      else noArrayData
                    ">
                      <div *ngFor="let item of row[rowProp.key]"
                        [innerHTML]="formatValue(item,rowProp) | arrayObjectKey: rowProp.ArrayObjectKey">
                        <!-- {{
                        formatValue(item,rowProp)
                        | arrayObjectKey: rowProp.ArrayObjectKey
                        }} -->
                      </div>
                    </span>
                    <ng-template #noArrayData> None </ng-template>
                  </span>
                  <span *ngSwitchCase="'date'">
                    {{ row[rowProp.key] | date: rowProp.format }}
                  </span>
                  <span *ngSwitchCase="'ip'">
                    <span class="d-flex flex-wrap" *ngIf="
                      tableService.hasData(row[rowProp.key]);
                      else noIPData
                    ">
                      <div class="ip" *ngFor="let item of row[rowProp.key]">
                        {{ formatValue(item,rowProp) }}
                      </div>
                    </span>
                    <ng-template #noIPData> None </ng-template>
                  </span>
                  <span *ngSwitchCase="'bytes'">
                    {{ formatValue(row[rowProp.key],rowProp) | formatBytes }}
                  </span>
                  <span *ngSwitchCase="'html'">
                    <span [innerHtml]="row[rowProp.key]"></span>
                  </span>
                  <span *ngSwitchCase="'input'">
                    <input class="input" type="text" [value]="row[rowProp.key]" [name]="rowProp.key"
                      (input)="modifyInput($event, row)" [class]="rowProp.width && rowProp.width">
                  </span>
                  <span *ngSwitchCase="'dropdown'">
                    <select [name]="rowProp.key" [id]="rowProp.key" [class]="rowProp.width && rowProp.width"
                      (change)="chooseOption($event, row)" [(ngModel)]="row[rowProp.key]" class="dropdown">
                      <option value="">Select</option>
                      <option *ngFor="let item of rowProp.options" [value]="item.value">{{item.view}}</option>
                    </select>
                  </span>
                  <span *ngSwitchCase="'multi-input'">
                    <config-multi [(ngModel)]="row[rowProp.key]" [type]="rowProp.inpType">
                    </config-multi>
                  </span>
                  <!-- <span ngSwitchCase="'multi-input'">
                  <mat-form-field floatLabel="never"  style="width:100%; ">
                    <mat-chip-list class="form-control form-control-sm col-11 customList"
                      #chipList1>
                      <mat-chip class="customChip" *ngFor="let data of row[rowProp.key]" [removable]="true" (removed)="remove_item(data, row, rowProp.key)" required>
                        {{data}}
                        <mat-icon matChipRemove> cancel</mat-icon>
                      </mat-chip>
                    <input [id]="rowProp.key" placeholder="&nbsp;&nbsp;{{placeholder}}" [formControl]="data" autocomplete="off" [matChipInputFor]="chipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add_item($event, row)" />
                    </mat-chip-list>
                  </mat-form-field>
                </span> -->
                </span>
                <span *ngIf="rowProp.copy">
                  <cosgrid-clipboard-text [value]="row[rowProp.key]"></cosgrid-clipboard-text>
                </span>
              </div>

            </td>

            <!-- <td *ngFor="let dropdown of dropdownValues;let i=index">
              <select [name]="dropdownHeaders[i]" [id]="dropdownHeaders[i]" (change)="chooseOption($event, row)"
                class="dropdown">
                <option value="">Select</option>
                <option *ngFor="let item of dropdown" [value]="item.value">{{item.view}}</option>
              </select>
            </td> -->

            <!-- status to show like colour but based on status -->
            <td *ngIf="needStatusBoolean && row['status']==true" style="width:90px"><span
                class="badge-include p-1">Fully included</span></td>
            <td *ngIf="needStatusBoolean && row['status']==false" style="width:90px"><span
                class="badge-not-include p-1">Not included</span></td>

            <!-- Status to show for rules in ruleset -->
            <td *ngIf="needStatus && row['select'] == 0"><span class="badge-not-include p-1">Not included</span></td>
            <td *ngIf="needStatus && row['select'] > 0 && row['select'] < row['total']"><span
                class="badge-partial p-1">Partial</span></td>
            <td *ngIf="needStatus && row['select'] > 0 && row['select'] == row['total']"><span
                class="badge-include p-1">Fully included</span></td>


            <!-- CHECKBOX TOGGLE FOR STATUS -->
            <td *ngIf="needStatusCheckbox && row['status'] == true"
              class="d-flex justify-content-center align-items-center">
              <input type="checkbox" name="included" value="{{row['sid']}}:{{row['rev']}}" checked
                (click)="clickCheckbox($event, row)">
            </td>
            <td *ngIf="needStatusCheckbox && row['status'] == false"
              class="d-flex justify-content-center align-items-center">
              <input type="checkbox" name="included" value="{{row['sid']}}:{{row['rev']}}"
                (click)="clickCheckbox($event, row)">
            </td>

            <!-- table action buttons  -->
            <td *ngIf="isConfig" class="text-center align-middle">
              <!-- Single Actions -->
              <cosgrid-table-actions *ngIf="needToggle && !needEdit && !needDelete && !needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && needEdit && !needDelete && !needView" (edit)="editRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && !needEdit && needDelete && !needView"
                (delete)="deleteRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && !needEdit && !needDelete && needView" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <!-- Pairs of Actions -->
              <cosgrid-table-actions *ngIf="needToggle && needEdit && !needDelete && !needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (edit)="editRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="needToggle && !needEdit && needDelete && !needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (delete)="deleteRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="needToggle && !needEdit && !needDelete && needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && needEdit && needDelete && !needView" (edit)="editRow(row)"
                (delete)="deleteRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && needEdit && !needDelete && needView" (edit)="editRow(row)"
                (view)="viewRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && !needEdit && needDelete && needView"
                (delete)="deleteRow(row)" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <!-- Triples of Actions -->
              <cosgrid-table-actions *ngIf="needToggle && needEdit && needDelete && !needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (edit)="editRow(row)" (delete)="deleteRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="needToggle && needEdit && !needDelete && needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (edit)="editRow(row)" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="needToggle && !needEdit && needDelete && needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (delete)="deleteRow(row)" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <cosgrid-table-actions *ngIf="!needToggle && needEdit && needDelete && needView" (edit)="editRow(row)"
                (delete)="deleteRow(row)" (view)="viewRow(row)">
              </cosgrid-table-actions>

              <!-- All Actions -->
              <cosgrid-table-actions *ngIf="needToggle && needEdit && needDelete && needView" [value]="row.enabled"
                (toggled)="toggledEnableDisable($event, row)" (edit)="editRow(row)" (delete)="deleteRow(row)"
                (view)="viewRow(row)">
              </cosgrid-table-actions>

              <!-- No Actions -->
              <ng-template #noNeedToggle>
                <cosgrid-table-actions *ngIf="!needEdit && !needDelete && !needView">
                </cosgrid-table-actions>

              </ng-template>
            </td>
            <td *ngIf="needButton" class="text-center align-middle">
              <span>
                <button class="btn btn-primary" (click)="buttonClick($event, row)">{{buttonName}}</button>
              </span>

            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="noMatch" class="noMatch">
        <p>
          No Matching Result(s).<span (click)="clearSearch()">
            Clear Search</span>
        </p>
      </div>
      <div class="legendContainer">
        <div class="legend" *ngFor="let legend of legends">
          <cosgrid-table-icon [icon]="legend.iconName"></cosgrid-table-icon>
          &nbsp;&nbsp;
          <p>{{ legend.label }}</p>
        </div>
      </div>
      <!-- <div>
          <mat-paginator [length]="100"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </div> -->
    </div>
  </span>
</div>
<cosgrid-datatable-footer *ngIf="isConfig && needRowAdd" [noRows]="noRows" (add)="addRow()">
</cosgrid-datatable-footer>

<cosgrid-no-conf [data]="rows" [loading]="refreshLoading" [isStatus]="!isConfig" [message]="nullMessage"
  [type]="type"></cosgrid-no-conf>