import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cosgrid-waf-pie-chart',
    templateUrl: './waf-pie-chart.component.html',
    styleUrls: ['./waf-pie-chart.component.scss'],
})
export class WafPieChartComponent implements OnInit {
    @Input() graph = { data: [], layout: { title: '', width: 400, height: 300, autosize: true } };
    constructor() {}

    ngOnInit() {}
}
