import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { EditViewIssueComponent } from './edit-view-issue/edit-view-issue.component';
import { SupportService, AlertService } from 'app/shared/services/misc';
import { GlobalService } from 'app/shared/services/core';

@Component({
    selector: 'cosgrid-track-ticket-status',
    templateUrl: './track-ticket-status.component.html',
    styleUrls: ['./track-ticket-status.component.scss'],
})
export class TrackTicketStatusComponent implements OnInit {
    issuesData;
    loading: boolean = true;
    currentTenantName: string = this.customerListService.getTenantName();

    constructor(
        private supportService: SupportService,
        private customerListService: CustomerListService,
        private global: GlobalService,
        private alert: AlertService,
        private dialog: MatDialog,
    ) {}

    isSuperAdmin: boolean = false;

    ngOnInit() {
        this.getIssues();
        this.isSuperAdmin = this.global.isSuperAdmin();
    }

    onoff(item) {
        item.enabled = !item.enabled;

        if (item.enabled) {
            item.status = 'OPEN';
        } else {
            item.status = 'CLOSE';
        }

        item['tenant_name'] = this.currentTenantName;
        this.supportService.changeIssueStatus(item).subscribe(
            (res: any) => {
                if (res.status === 'OPEN') this.alert.swalSuccess('Successfully opened an issue ');
                else {
                    this.alert.swalSuccess('Successfully closed an issue');
                }
                this.getIssues();
            },
            (err) => {},
        );
    }

    openDialog(data) {
        const dialogRef = this.dialog.open(EditViewIssueComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getIssues();
            }
        });
    }

    getIssues() {
        this.loading = true;
        this.supportService.getIssues(this.currentTenantName).subscribe(
            (res) => {
                this.issuesData = res;
                this.loading = false;
            },
            (err) => {
                this.loading = false;
            },
        );
    }
}
