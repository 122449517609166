// BASE_URLS
const AUTH = 'auth';
const DASHBOARD = 'dashboard';
const SD_WAN_GATEWAY = 'device';
const MZA = 'mza';
const NETWORK = 'network';
const NDR = 'ndr';
const ORGANIZATION = 'organization';
const SUPER_ADMIN = 'superAdmin';
const SETTINGS = 'settings';

// AUTH ROUTES
const LOGIN = 'login';
const AUTH_LOGIN = `${AUTH}/login`;
const REGISTER = `sign-up`;
const MICROZACCESS = 'microZAccess';
const COSGRIDSSO = 'cosgridsso';
const FORGOT_PASSWORD = `forgotPassword`;
const RESET_PASSWORD = `resetPassword`;
const ACTIVATE = `activate`;
const SOCIAL_SIGN_UP = `social-signup`;
const FREE_TRIAL = 'free-trial';

// SD_WAN_CONFIGURE
const SD_WAN_CONFIGURE = `${SD_WAN_GATEWAY}/configure`;

// SD_WAN_STATUS
const SD_WAN_STATUS = `${SD_WAN_GATEWAY}/status`;

// mza atatus
const MZA_STATUS = `${MZA}/status`;

const SOMETHING_WENT_WRONG = `something-went-wrong`;

const TEAMS = `teams`;
const LOGS = `logs`;

export const RouteConstants = {
    AUTH,
    DASHBOARD,
    SD_WAN_GATEWAY,
    MZA,
    NETWORK,
    NDR,
    ORGANIZATION,
    SUPER_ADMIN,
    SETTINGS,
    TEAMS,
    LOGS,

    LOGIN,
    AUTH_LOGIN,
    REGISTER,
    MICROZACCESS,
    FREE_TRIAL,
    COSGRIDSSO,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    ACTIVATE,
    SOCIAL_SIGN_UP,

    SD_WAN_CONFIGURE,
    SD_WAN_STATUS,

    MZA_STATUS,

    SOMETHING_WENT_WRONG,
};
