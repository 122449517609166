import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
    selector: 'cosgrid-overlay-loader',
    templateUrl: 'loader.component.html',
})
export class LoaderComponent implements OnChanges {
    @Input() show = true;
    @Input() fakeText = true;
    @Input() width = '600px'

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.show = changes.show.currentValue;
        } catch (err) {
            this.show = false;
        }
    }
}
