import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { MapsAPILoader } from '@agm/core';
import { Component, Input, OnChanges } from '@angular/core';

declare let google: any;

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnChanges {
    @Input() devices: Array<any> = [];
    previousdevices: Array<any> = [];
    lat: any = 13.0827;
    lng: any = 80.2707;
    latlngBounds: any;
    latlongs = [];
    mapStyles = [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#4694ec',
                },
                {
                    visibility: 'on',
                },
            ],
        },
    ];
    mapUrl = '';
    count = 0;
    vpnDashboard;
    device = {
        deviceId: 'd200797d-616e-4ebd-a5f0-cd957bb39b',
        deviceKey: 'VSNcyUHGcWFSMkFfvxCEDqtUk1hgaq',
        deviceStatus: 'connected',
        name: 'Device-HUB',
        id: '5f01fc7a-0769-466a-b4db-d132648d29b2',
        lastSeenTime: '2023-01-27T07:50:54.188485Z',
        latitude: '18.516726',
        location: 'Mumbai, India',
        longitude: '73.856255',
        os: 'Windows',
        publicIp: '122.164.86.204',
        vpnIp: '100.64.0.1',
    };

    constructor(private mapsAPILoader: MapsAPILoader, private store: Store<CosgridAppStore>) {}

    ngOnChanges() {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
        this.subscribeToDevicesSubject();
    }

    subscribeToDevicesSubject() {
        if (this.vpnDashboard) {
            let vpnd;
            if (this.devices?.length !== 0) {
                vpnd = [...this.devices, this.device];
                this.previousdevices = [...this.previousdevices, this.device];
            }

            if (!this.arrayCompare(this.previousdevices, vpnd) || this.count == 0) {
                // only clear map when the contents of device changes // TODO: stop reinitializing the map when location doesent change
                this.count++;
                this.clearAllMarkers();
                for (let i = 0; i < vpnd?.length; i++) {
                    if (vpnd[i].geographicalZone) {
                        if (vpnd[i].geographicalZone !== '0') {
                            if (vpnd[i].deviceStatus == 'connected' || vpnd[i].deviceStatus == 'online') {
                                this.getGeoLocation(
                                    vpnd[i].geographicalZone,
                                    'https://cdn.cosgrid.com/website/COSGridNet/assets/green-dot.png',
                                    {
                                        status: vpnd[i].deviceStatus,
                                        ipAddress: vpnd[i].ipAddress,
                                        name: vpnd[i].name,
                                    },
                                    vpnd[i].latitude,
                                    vpnd[i].longitude,
                                );
                            } else if (vpnd[i].deviceStatus == 'offline') {
                                this.getGeoLocation(
                                    vpnd[i].geographicalZone,
                                    'https://cdn.cosgrid.com/website/COSGridNet/assets/red-dot.png',
                                    {
                                        status: vpnd[i].deviceStatus,
                                        ipAddress: vpnd[i].ipAddress,
                                        name: vpnd[i].name,
                                    },
                                    vpnd[i].latitude,
                                    vpnd[i].longitude,
                                );
                            }
                        }
                    } else {
                        if (vpnd[i].deviceStatus == 'connected' || vpnd[i].deviceStatus == 'online') {
                            this.getGeoLocation(
                                vpnd[i].location,
                                'https://cdn.cosgrid.com/website/COSGridNet/assets/green-dot.png',
                                {
                                    status: vpnd[i].deviceStatus,
                                    ipAddress: vpnd[i].ipAddress,
                                    name: vpnd[i].name,
                                },
                                vpnd[i].latitude,
                                vpnd[i].longitude,
                            );
                        } else if (vpnd[i].deviceStatus == 'offline') {
                            this.getGeoLocation(
                                vpnd[i].location,
                                'https://cdn.cosgrid.com/website/COSGridNet/assets/red-dot.png',
                                {
                                    status: vpnd[i].deviceStatus,
                                    ipAddress: vpnd[i].ipAddress,
                                    name: vpnd[i].name,
                                },
                                vpnd[i].latitude,
                                vpnd[i].longitude,
                            );
                        }
                    }
                }
            }
            this.previousdevices = [...this.devices];
        } else {
            if (!this.arrayCompare(this.previousdevices, this.devices) || this.count == 0) {
                // only clear map when the contents of device changes // TODO: stop reinitializing the map when location doesent change
                this.count++;
                this.clearAllMarkers();
                for (let i = 0; i < this.devices.length; i++) {
                    if (this.devices[i].geographicalZone != 0) {
                        if (this.devices[i].deviceStatus === 'running' || this.devices[i].deviceStatus === 'modified') {
                            this.getGeoLocation(
                                this.devices[i].geographicalZone,
                                'https://cdn.cosgrid.com/website/COSGridNet/assets/green-dot.png',
                                {
                                    status: this.devices[i].deviceStatus,
                                    ipAddress: this.devices[i].ipAddress,
                                    name: this.devices[i].name,
                                },
                                this.devices[i].latitude,
                                this.devices[i].longitude,
                            );
                        } else if (this.devices[i].deviceStatus === 'error') {
                            this.getGeoLocation(
                                this.devices[i].geographicalZone,
                                'https://cdn.cosgrid.com/website/COSGridNet/assets/red-dot.png',
                                {
                                    status: this.devices[i].deviceStatus,
                                    ipAddress: this.devices[i].ipAddress,
                                    name: this.devices[i].name,
                                },
                                this.devices[i].latitude,
                                this.devices[i].longitude,
                            );
                        }
                    }
                }
            }
            this.previousdevices = [...this.devices];
        }
    }

    arrayCompare(arr1: Array<any>, arr2: Array<any>) {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].deviceStatus !== arr2[i].deviceStatus) {
                return false;
            }
        }
        return true;
    }

    getGeoLocation(address: string, markerColor: string, deviceInfo: any, lat, lng) {
        try {
            const coordinates = { latt: lat, long: lng, label: address, icon: markerColor, deviceInfo: deviceInfo };
            this.latlongs.push(coordinates);

            this.setMapBound();
        } catch (err) {}
    }

    clearAllMarkers() {
        this.latlongs = [];
        this.setMapBound();
    }

    setMapBound() {
        this.mapsAPILoader.load().then(() => {
            this.latlngBounds = new google.maps.LatLngBounds();
            for (const mm of this.latlongs) {
                this.latlngBounds.extend(new google.maps.LatLng(mm.latt, mm.long));
            }
        });
    }

    onMouseOver(infoWindow, $event: MouseEvent) {
        infoWindow.open();
    }

    onMouseOut(infoWindow, $event: MouseEvent) {
        infoWindow.close();
    }
}
