import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class GlobalConstants {
    DASHBOARD_POLLING_TIME = 15000; // 15secs
    // endpointUrl = localStorage.getItem('endpoint') || 'http://127.0.0.1:8080/';

    // endpointUrl = "$#customEndpoint$#"

    // endpointUrl = environment.endpoint;

    // tempSubDomain = location.href.split('//')[1].split('.')[0]
    // subDomain = this.tempSubDomain?(this.tempSubDomain === 'app' || this.tempSubDomain === 'cosgrid') ?'':`${this.tempSubDomain}.`:''

    // endpointUrl = `https://${this.subDomain}cosgridnetworks.in/`;

    // endpointUrl = `https://${this.subDomain}cosgridnetworks.in/`;

    endpointUrl = getEndpoint();

    // licenseEndpoint = "https://license.cosgrid.net/"
    licenseEndpoint = 'http://licns.cosgrid.com:1997/';

    licenseSv_tenants = this.licenseEndpoint + 'tenants';
    licenseSv_License = this.licenseEndpoint + 'license';
    licenseSv_Expired = this.licenseEndpoint + 'expired';
    licenseSv_validate = this.licenseEndpoint + 'validated';

    // endpointUrl = `http://35.154.229.251:4200/`;

    // endpointUrl = `orangeretailfinance.mydomain.com`;

    // endpointUrl   = 'http://35.154.229.251:1997/'; //ec2 production
    //  endpointUrl = 'http://192.168.137.156:8003/';      //local test
    //  endpointUrl = 'http://192.168.137.156:8003/';      //local test
    //  endpointUrl = 'http://192.168.0.102:8003/';      //local test2
    //  endpointUrl = 'http://13.232.57.39:9000/';      //ec2 test1
    //  endpointUrl = "http://13.232.140.176:8080/";

    // endpointUrl = 'http://35.154.229.251:9000/';  //ec2 test
    // endpointUrl = 'http://192.168.3.132:8000/';  //cedge
    // endpointUrl = 'http://220.227.237.10:8080/';
    // endpointUrl = 'http://115.249.75.121:8080/';

    // *********************************************************BASE ENDPOINT****************************************************//
    endpoint = this.endpointUrl + 'api/v1/';
    wsendpoint = this.endpointUrl.includes('https')
        ? 'wss' + this.endpointUrl.slice(5) + 'ws/status'
        : 'ws' + this.endpointUrl.slice(4) + 'ws/status';

    // *********************AUTH**************************//

    auth = this.endpoint + 'auth/';

    LOGIN_URL = this.auth + 'login/';
    MFALOGIN_URL = this.auth + '2flogin/';
    SSO_REDIRECT_URL = this.auth + 'redirect-url/';
    LOGOUT_URL = `${this.auth}logout/`;
    SSO_CHECK_URL = this.auth + 'saml-enabled';
    GOOGLE_LOGIN = this.auth + 'googlelogin/';
    PASSWORD_RESET = `${this.auth}password/reset/`;
    PASSWORD_CHANGE = `${this.auth}password/change/`;
    REFRESH = `${this.auth}refresh/`;
    REMEMEBER_ME = `${this.auth}remember-me/`;
    USER_DOMAIN = `${this.auth}user-domain/`;

    // ***********END OF AUTH***********//

    // *************************TENANT*******************************************//

    tenant = this.endpoint + 'tenant/';
    ws_tenant = this.wsendpoint + '/tenant/';

    tenant_device = this.tenant + 'device/';
    tenant_tenant = this.tenant + 'data/';
    tenant_network = this.tenant + 'network/';
    tenant_template = this.tenant + 'template/';
    tenant_package = this.tenant + 'package/';
    tenant_appflow = this.tenant + 'appflow/';
    tenant_alert = this.tenant + 'alert/';
    tenant_report = this.tenant + 'report/';
    tenant_support = this.tenant + 'support/';
    tenant_version = this.tenant + 'alert/app-version/';
    tenant_config = this.tenant + 'config/';
    tenant_app = this.tenant + 'alert/app-notifications/';

    // *********TENANT_DEVICE******************//
    DEVICE_LIST = this.tenant_device + 'devicelist/'; //
    DEVICE_ADD = this.tenant_device + 'deviceadd/'; //
    ADMIN_DEVICE = this.tenant_device + 'admin-device/';
    RESTART_DEVICE = this.tenant_device + 'restart-device/';
    DEACTIVATE_DEVICE = this.tenant_device + 'deactivate-device/';
    ACTIVATE_DEVICE = this.tenant_device + 'activate-device/';
    DEVICE_UNCLAIM = this.tenant_device + 'device-unclaim/'; //
    UNCLAIMED_DEVICES = this.tenant_device + 'unclaimed-devices/'; //
    DEVICE_CLAIM = this.tenant_device + 'device-claim/'; //
    MEMORY = this.tenant_device + 'memory/';
    TENANT_SSO_INFO = this.tenant_device + 'saml-info';
    MOVPN_DEVICE = this.tenant + 'config/mza-policy/';

    // *************END OF TENANT_DEVICE**********//

    // *************TENANT_TENANT**********//
    ADMIN_TENANT_SIGNUP = this.tenant_tenant + 'signup/'; //
    TENANT_SIGNUP = this.tenant_tenant + 'user-signup/'; //
    TENANT_ACTIVATE = 'https://cosgridnetworks.in/api/v1/auth/activate/'; //
    TENANT_USER_ADD = this.tenant_tenant + 'useradd/'; //
    TENANT_LIST = this.tenant_tenant + 'tenantlist/'; //
    TENANT_USER_LIST = this.tenant_tenant + 'user-list/'; //
    TENANT_GROUP_LIST = this.tenant_tenant + 'group-list/'; //
    TENANT_ADMIN_DASHBOARD = this.tenant_tenant + 'admin-dashboard/'; //
    TENANT_ADMIN_TENANT = this.tenant_tenant + 'admin-tenant/'; //
    TENANT_USERNAME = this.tenant_tenant + 'username/'; //
    TENANT_USERLIST = this.tenant_tenant + 'userlist/'; //
    TENANT_SESSION_LOG = this.tenant_tenant + 'sessionlog/'; //
    TENANT_MAIL = this.tenant_tenant + 'tenant-mail/'; //
    TENANT_ADMIN_ACTIONS = this.tenant_tenant + 'admin-actions/';
    TENANT_COMMERCIAL_DATA = this.tenant_tenant + 'commercial/';

    // ************END OF TENANT_TENANT *************/

    // *************TENANT_NETWORK**********//

    TENANT_NETWORKLIST = this.tenant_network + 'networklist/';
    TENANT_DEVICENETWORK = this.tenant_network + 'devicenetwork/';
    TENANT_ADD_NETWORK = this.tenant_network + 'addnetwork/';
    TENANT_ADMIN_NETWORK = this.tenant_network + 'admin-network/';

    // ************END OF TENANT_NETWORK *************/

    // *************TENANT_TEMPLATE**********//

    TENANT_TEMPLATELIST = this.tenant_template + 'templatelist/';
    TENANT_ADD_TEMPLATE = this.tenant_template + 'addtemplate/';
    TENANT_ADMIN_TEMPLATE = this.tenant_template + 'admin-template/';
    TENANT_TEMPLATE_CONFIG = this.tenant_template + 'template-config/';
    TENANT_TEMPLATE_DEVICE_BIND = this.tenant_template + 'devicebind/';

    // ************END OF TENANT_NETWORK *************/

    // *************TENANT_PACKAGE**********//

    TENANT_INSTALLED_PACKAGE = this.tenant_package + 'installed-package/';
    TENANT_INSTALL_PACKAGE = this.tenant_package + 'install-package/';
    TENANT_REMOVE_PACKAGE = this.tenant_package + 'remove-package/';
    TENANT_INFO_PACKAGE = this.tenant_package + 'info-package/';
    TENANT_UPGRADE_PACKAGE = this.tenant_package + 'upgrade-package/';

    // ************END OF TENANT_PACKAGE *************/

    // *************TENANT_APPFLOW**********//

    TENANT_APPFLOW_SERVER = this.tenant_appflow + 'appflow-server/';
    TENANT_APPFLOW_CLIENT = this.tenant_appflow + 'appflow-client/';
    TENANT_APPFLOW_APP = this.tenant_appflow + 'appflow-application/';
    TENANT_APPFLOW_FLOW = this.tenant_appflow + 'appflow-flow/';
    TENANT_APPFLOW_ACTIVEFLOWS = this.tenant_appflow + 'appflow-activeflows/';
    TENANT_APPFLOW_TOPSENDERS = this.tenant_appflow + 'appflow-topsenders/';
    TENANT_APPFLOW_TOPAPPLICATIONS = this.tenant_appflow + 'appflow-topapplications/';
    TENANT_APPFLOW_TOPHOSTS = this.tenant_appflow + 'appflow-tophosts/';
    TENANT_APPFLOW_TOPTALKERS = this.tenant_appflow + 'appflow-realtime-toptalkers/';
    TENANT_APPFLOW_TOPPORTS = this.tenant_appflow + 'appflow-topports/';
    TENANT_APPFLOW_HOSTNAMES = this.tenant_appflow + 'appflow-hostnames/';
    TENANT_APPFLOW_HOSTDEATILS = this.tenant_appflow + 'appflow-hostdetails/';
    TENANT_APPFLOW_NETWORKDATA = this.tenant_appflow + 'appflow-networkdata/';

    TENANT_APPFLOW_REALTIME_HOSTFLOW = this.tenant_appflow + 'appflow-realtime-host-flow-details/';
    TENANT_APPFLOW_NETWORKS_DATA = this.tenant_appflow + 'appflow-networks-data/';
    TENANT_APPFLOW_IFNAMES = this.tenant_appflow + 'appflow-ifnames/';

    TENANT_APPFLOW_HOSTPROTODETAILS = this.tenant_appflow + 'appflow-host-proto-details/';
    TENANT_APPFLOW_HOSTPACKETDETAILS = this.tenant_appflow + 'appflow-host-pckt-distribution/';
    TENANT_APPFLOW_HOSTPORTDETAILS = this.tenant_appflow + 'appflow-host-port-details/';
    TENANT_APPFLOW_HOSTAPPLICATIONDETAILS = this.tenant_appflow + 'appflow-host-applications/';
    TENANT_APPFLOW_HOSTFLOWSDETAILS = this.tenant_appflow + 'appflow-host-flows/';

    // ************END OF TENANT_APPFLOW *************/

    // *************TENANT_ALERT**********//

    TENANT_ALERT = this.tenant_alert + 'grafana-id/';
    TENANT_APP_NOTIFICATIONS = this.tenant_alert + 'app-notifications/';

    // ************END OF TENANT_ALERT *************/

    // *************TENANT_REPORT**********//

    TENANT_REPORT = this.tenant_report + 'tenant-report/';

    // *************END OF TENANT_REPORT**********//

    // *************TENANT_SUPPORT**********//

    TENANT_SUPPORT = this.tenant_support + 'ticket/';
    // *************END OF TENANT_SUPPORT**********//

    // *************TENANT_LOGS**********//
    LOGIN_LOGS = this.tenant_tenant + 'login-log/';
    ACTIVITY_LOGS = this.tenant_tenant + 'activity-log/';
    // *************END OF TENANT_LOGS**********//

    // *************TENANT_LOGS**********//
    TENANT_PREFERNCE = this.tenant_config + 'config-preference/';
    TENANT_UDA = this.tenant_config + 'udf/';
    TENANT_OVERLAY_MESH_SERVER = this.tenant_config + 'ovn/';
    TENANT_OVERLAY_VPN = this.tenant_config + 'secgrp/';
    TENANT_MSVN_DETAILS = this.tenant_config + 'msvndetails/';
    // *************************END OF TENANT******************************//

    // *************************DEVICE******************************//

    device = this.endpoint + 'device/';
    vpnDevice = this.endpoint + 'tenant/device/endpoint/';
    deleteDevice = this.endpoint + 'tenant/device/deregister-endpoint/';
    ws_device = this.wsendpoint + '/device/';

    device_config = this.device + 'config/';
    device_vrrp = this.device + 'vrrp/';
    device_firewall = this.device + 'firewall/';
    device_nat = this.device + 'nat/';
    device_sdwan = this.device + 'sdwan/';
    device_interface = this.device + 'interface/';
    device_route = this.device + 'route/';
    device_vlan = this.device + 'vlan/';
    device_ssh = this.device + 'ssh/';
    device_ipsec = this.device + 'ipsec/';
    device_dhcp = this.device + 'dhcp/';
    device_qos = this.device + 'qos/';
    device_wifi = this.device + 'wifi/';
    device_snmpd = this.device + 'snmpd/';
    device_grafana = this.device + 'monitor/';
    device_grafanaalert = this.device + 'alert/';
    device_usage = this.device + 'usage/';
    device_contentfilter = this.device + 'contentfilter/';
    device_bgp = this.device + 'bgp/';
    device_system = this.device + 'system/';
    device_linkbonding = this.device + 'linkbonding/';
    device_autovpn = this.device + 'autovpn/';
    device_report = this.device + 'report/';
    device_overlay = this.device + 'movpn/';

    // *************DEVICE_CONFIG**********//

    DEVICE_RESET = '/reset/';
    DEVICE_BACKUP = '/backup/';
    DEVICE_RESTORE = '/restore/';
    DEVICE_REBOOT = '/reboot/';
    DEVICE_CONFIG_REFRESH = '/config-refresh/';
    DEVICE_COLLECTD_REFRESH = '/collectd-refresh/';
    DEVICE_PHY_INTERFACES = '/phy-interfaces/';
    DEVICE_DEVICE_CERTS = '/device-certs/';
    DEVICE_ENABLE_CONFIGS = '/enable-configs/';
    DEVICE_TTYD = '/ttyd/';

    // ****************END OF device_config*********************//

    // ****************device_vrrp*********************//
    DEVICE_VRRP = '/vrrp/';
    // ****************END OF device_vrrp*********************//

    // ****************device_firewall*********************//

    DEVICE_FIREWALL = '/firewall/';
    DEVICE_FIREWALL_ZONE = '/firewall-zone/';
    DEVICE_FIREWALL_RULE = '/firewall-rule/';
    DEVICE_FIREWALL_FORWARD = '/firewall-forward/';
    DEVICE_IPSET = '/ipset/';

    // ****************END OF device_firewall*********************//

    // ****************device_NAT*********************//
    DEVICE_NAT = '/nat/';
    DEVICE_SNAT = '/nat-snat/';
    DEVICE_DNAT = '/nat-dnat/';
    DEVICE_PFNAT = '/nat-port-forwarding/';
    // ****************END OF NAT*********************//

    // ****************device_sdwan*********************//

    DEVICE_SDWAN_POLICY = '/sdwan-policy/';
    DEVICE_SDWAN_INTERFACE = '/sdwan-interface/';
    DEVICE_SDWAN_MEMBER = '/sdwan-member/';
    DEVICE_SDWAN_RULE = '/sdwan-rule/';
    DEVICE_SDWAN_NEWPOLICY = '/sdwan-newpolicy/';
    DEVICE_SDWAN_NEWMEMBER = '/sdwan-newmember/';
    DEVICE_SDWAN_CONFIGS = '/sdwan-configs/';

    // ****************END OF device_sdwan*********************//

    // ****************device_interface*********************//
    DEVICE_INTERFACE = '/interface/';
    DEVICE_INTERFACE_IVP6 = '/interface-ivp6/';
    DEVICE_IFACE_UPDOWN = '/iface-updown/';
    // ****************END OF device_interface*********************//

    // ****************device_route*********************//

    DEVICE_ROUTES = '/routes/';
    DEVICE_MOVPNS = '/movpns/';
    DEVICE_ROUTES_IV6 = '/routes-iv6/';
    // ****************END OF device_route*********************//

    // ****************device_vlan*********************//
    DEVICE_VLAN = '/vlan/';
    // ****************END OF device_vlan*********************//

    // ****************device_SSH*********************//

    DEVICE_SSH = '/ssh/';

    // ****************END OF device_SSH*********************//

    // ****************device_ipsec*********************//
    DEVICE_IPSEC = '/ipsec/';
    DEVICE_IPSEC_REMOTE = '/ipsec-remote/';
    DEVICE_IPSEC_TUNNEL = '/ipsec-tunnel/';
    DEVICE_IPSEC_P1_PROPOSAL = '/ipsec-p1_proposal/';
    DEVICE_IPSEC_P2_PROPOSAL = '/ipsec-p2_proposal/';
    DEVICE_IPSEC_REFRESH = '/ipsec-refresh/';
    DEVICE_IPSEC_ENABLE = '/ipsec-enable/';
    // ****************END OF device_ipsec*********************//

    // ****************device_dhcp*********************//

    DEVICE_DHCP = '/dhcp/';
    DEVICE_DHCPODHCPD = '/dhcpodhcpd/';
    DEVICE_DHCPDNSMASQ = '/dhcpdnsmasq/';

    // ****************END OF device_dhcp*********************//

    // ****************device_qos*********************//

    DEVICE_QOS_INTERFACE = '/qos-iface/';
    DEVICE_QOS_RULE = '/qos-rule/';
    DEVICE_QOS_CLASSGROUP = '/qos-classgroup/';
    DEVICE_QOS_CLASSES = '/qos-classes/';
    // ****************END OF device_qos*********************//

    // ****************device_wifi*********************//

    DEVICE_WIFI_DEVICE = '/wifi-device/';
    DEVICE_WIFI_INTERFACE = '/wifi-iface/';
    // ****************END OF device_wifi*********************//

    // ****************device_snmpd*********************//

    DEVICE_SNMPD_COM2SEC = '/snmpd-com2sec/';
    DEVICE_SNMPD_GROUP = '/snmpd-group/';
    DEVICE_SNMPD_ACCESS = '/snmpd-access/';
    DEVICE_SNMPD_EXEC = '/snmpd-exec/';
    DEVICE_SNMPD_SYSTEM = '/snmpd-system/';
    DEVICE_SNMPD_USER = '/snmpd-user/';
    DEVICE_SNMPD_TRAP_RECEIVER = '/snmpd-trap-receiver/';
    DEVICE_SNMPD_WALK = '/snmpd-walk/';
    // ****************END OF device_snmpd*********************//

    // ****************device_grafana*********************//

    DEVICE_GRAFANA_INTERFACE = '/iface/';
    DEVICE_GRAFANA_PING = '/ping/';
    DEVICE_GRAFANA_METRICS = '/metrics/';
    DEVICE_GRAFANA_INTERFACE_LIST = '/interface-list/';
    // ****************END OF device_grafana*********************//

    // ****************device_grafanaalert*********************//

    DEVICE_GRAFANA_ALERT = '/alert/';
    DEVICE_GRAFANA_ALERT_DEVICE = '/alert-device/';
    DEVICE_GRAFANA_ALERT_WAN = '/alert-wan/';
    DEVICE_GRAFANA_ALERT_PERFORMANCE = '/alert-performance/';
    DEVICE_GRAFANA_ALERT_IPSEC = '/alert-ipsec/';
    DEVICE_NOTIFICATIONS = '/notifications/';

    // ****************END OF device_grafanaalert*********************//

    // ****************device_usage*********************//

    DEVICE_URL_FILTER = '/url-filter/';
    DEVICE_PDFDATA = '/pdfdata/';

    // ****************END OF device_usage*********************//

    // ****************device_contentfilter*********************//

    DEVICE_CONTENT_CATEGORY = '/content-category/';
    DEVICE_CONTENT_URL = '/content-url/';
    // ****************END OF device_contentfilter*********************//

    // ****************device_bgp*********************//

    DEVICE_BGP_DEFAULT = '/bgp-default/';
    DEVICE_BGP_NEIGHBOR = '/bgp-neighbor/';
    DEVICE_BGP_ROUTEMAP = '/bgp-routemap/';

    // ****************END OF device_bgp*********************//

    // ****************device_system*********************//

    DEVICE_SYSTEM = '/system/';
    DEVICE_SYSTEM_NTP = '/system-ntp/';
    // ****************END OF device_system*********************//

    // ****************device_linkbonding*********************//

    DEVICE_LINK_BONDING = '/link-bonding/';
    DEVICE_LB_DEFAULT = '/lb-default/';
    DEVICE_SERVER_LB_REFRESH = '/lb-server-refresh/';
    DEVICE_CLIENT_LB_REFRESH = '/lb-client-refresh/';
    DEVICE_INTERNET_BONDING = '/internet-bonding/';
    DEVICE_NETWORK_ACCELERATION = '/network-acceleration/';
    // ****************END OF device_linkbonding*********************//

    // ****************device_autovpn*********************//

    DEVICE_AUTOVPN_DEFAULT = '/autovpn-default/';
    DEVICE_AUTOVPN = '/autovpn/';
    // ****************END OF device_autovpn*********************//

    // ****************END OF device_report*********************//
    DEVICE_REPORT_DASHBOARD = '/report-dashboard/';
    // ****************END OF device_report*********************//
    // *************************END OF DEVICE************************************//

    // ******************************STATUS*************************************//

    status = this.endpoint + 'status/';

    status_device = this.status + 'device/';
    status_network = this.status + 'network/';
    status_sdwan = this.status + 'sdwan/';
    status_tunnel = this.status + 'tunnel/';
    status_firewall = this.status + 'firewall/';
    status_usage = this.status + 'usage/';
    status_log = this.status + 'log/';
    status_traffic = this.status + 'traffic/';
    status_grafana = this.status + 'monitor/';
    status_tenant = this.status + 'tenant/';
    status_diagnostics = this.status + 'diagnostics/';
    status_netflow = this.status + 'netflow/';

    // ****************status_device*********************//

    STATUS_SYSTEM_STATUS = '/system-status/';
    STATUS_SYSTEM_BOARD = '/system-board/';
    STATUS_SYSTEM_INFO = '/system-info/';
    STATUS_SYSTEM_LOG = '/system-log/';
    STATUS_SYSTEM_PROCESS = '/system-process/';
    // ****************END OF status_device*********************//

    // ****************status_network*********************//

    STATUS_ROUTE_STATUS = '/route-status/';
    STATUS_ARP_STATUS = '/arp-status/';
    STATUS_INTERFACE_STATUS = '/interface-status/';
    STATUS_DEVICESTATUSALL = '/devicestatusall/';
    STATUS_DHCP_LEASES = '/dhcp-leases/';
    STATUS_LAN_STATUS = '/lan-status/';
    STATUS_WAN_STATUS = '/wan-status/';
    STATUS_BGP_STATUS = '/bgp-status/';
    STATUS_BGP_ROUTE_STATUS = '/bgp-status/';

    // ****************END OF status_network*********************//

    // ****************status_sdwan*********************//

    STATUS_MWAN3_STATUS = '/sdwan-status/';
    // ****************END OF status_sdwan*********************//

    // ****************status_tunnel*********************//

    STATUS_IPSEC_STATUS = '/ipsec-status/';
    STATUS_LB_STATUS = '/lb-status/';
    // ****************END OF status_tunnel*********************//

    // ****************status_firewall*********************//

    STATUS_FIREWALL_STATUS = '/firewall-status/';
    STATUS_FIREWALL_CHAIN_STATUS = '/firewall-chain-status/';
    STATUS_IPSET_STATUS = '/ipset-status/';
    // ****************END OF status_firewall*********************//

    // ****************status_usage*********************//

    STATUS_URLFILTER_STATUS = '/urlfilter-status/';
    STATUS_BANDWIDTH_DATA = '/bandwidth-data/';
    STATUS_URL_TRACKING = '/url-tracking/';

    // ****************END OF status_usage*********************//

    // ****************status_log*********************//

    STATUS_LOGS = '/logs/';
    // ****************END OF status_log*********************//
    // ****************status_traffic*********************//
    // TENANT LEVEL
    STATUS_TRAFFIC_TOP_DEVICES = 'top-sites/';
    STATUS_TRAFFIC_TOP_TENANT_PROTO = 'top-protocols/';
    STATUS_TRAFFIC_TOP_TENANT_APPLICATION = 'top-applications/';
    STATUS_TRAFFIC_TOP_TENANT_USERS = 'top-talkers/';
    STATUS_TRAFFIC_TOP_TENANT_SOURCES = 'top-sources/';
    STATUS_TRAFFIC_TOP_TENANT_DESTINATION = 'top-destinations/';

    STATUS_TRAFFIC_TOP_TENANT_INDIVIDUAL_USER_DATA = '/user-data/';

    // DEVICE LEVEL
    STATUS_TRAFFIC_TOP_TALKERS = '/traffic-top-talkers/';
    STATUS_TRAFFIC_TOP_DESTINATIONS = '/traffic-top-destinations/';
    STATUS_TRAFFIC_TOP_SERVER_PORTS = '/traffic-top-server-ports/';
    STATUS_TRAFFIC_TOP_CLIENT_PORTS = '/traffic-top-client-ports/';
    STATUS_TRAFFIC_TOP_PROTOCOLS = '/traffic-top-protocols/';
    // ****************END OF status_traffic*********************//

    // ****************status_grafana*********************//

    STATUS_GRAFANA_ALERT_HISTORY = '/alert-history/';
    STATUS_GRAFANA_ALERT_HISTORY_WAN_REACHABILITY = '/alert-history-wan-reachability/';
    STATUS_GRAFANA_ALERT_HISTORY_DEVICE_REACHABILITY = '/alert-history-device-reachability/';
    STATUS_GRAFANA_ALERT_HISTORY_PERFORMANCE_REACHABILITY = '/alert-history-performance-reachability/';
    STATUS_GRAFANA_ALERT_HISTORY_IPSEC_REACHABILITY = '/alert-history-ipsec-reachability/';

    // ****************END OF status_grafana*********************//

    // ****************status_tenant*********************//

    STATUS_NETWORK_MONITOR = '/network-monitor/';
    STATUS_TENANT_INFO_STATUS = '/tenant-info-status/';
    STATUS_TENANT_INFO = this.status_tenant + 'tenant-info/';
    STATUS_TENANT_MONITOR = this.status_tenant + 'tenant-monitor/';
    STATUS_TENANT_ALERT_STATUS = 'tenant-alert-status/';
    STATUS_TENANT_ALERT_DEVICE_STATUS = 'device-tenant-alert-status/';
    STATUS_TENANT_ALERT_ALARM_STATUS = 'alarm-tenant-alert-status/';
    STATUS_TENANT_ALERT_TIME_SETTINGS = 'settings-alarms-tenant/';
    STATUS_DEVICE_ALERT_TIME_SETTINGS = 'device-alarm-settings/';
    STATUS_TENANT_SERVICES = this.status_tenant + 'services-status-tenant/';

    // ****************END OF status_tenant*********************//

    // ****************status_diagnostics*********************//

    STATUS_PING = '/ping/';
    STATUS_TRACEROUTE = '/traceroute/';
    STATUS_NSLOOKUP = '/nslookup/';
    STATUS_TCPDUMP = '/tcpdump/';

    STATUS_WS_DIAGNOSTICS = this.wsendpoint + '/device/diagnostics/';

    // ****************END OF status_diagnostics*********************//

    // ****************status_netflow*********************//
    STATUS_TOP_PROTO = '/top-proto/';
    STATUS_TOP_DEST = 'top-destinations/';
    STATUS_TOP_CLIENT = '/top-client-ports/';
    STATUS_TOP_SERVER = '/top-server-ports/';
    STATUS_TOP_TRAFFIC = '/traffic-total-in/';
    STATUS_TOP_TALKERS = '/top-talkers/';

    // ****************END OF status_netflow*********************//
    // *************************END OF STATUS************************************//

    // *************************END OF TEMPLATE************************************//

    template = this.endpoint + 'template/';

    TEMPLATE_GENERAL = 'general/';
    TEMPLATE_ROUTES = 'routes/';
    TEMPLATE_INTERFACE = 'interface/';
    TEMPLATE_MWAN3_POLICY = 'mwan3-policy/';
    TEMPLATE_MWAN3_INTERFACE = 'mwan3-interface/';
    TEMPLATE_MWAN3_MEMBER = 'mwan3-member/';
    TEMPLATE_MWAN3_RULE = 'mwan3-rule/';
    TEMPLATE_MWAN3_NEWPOLICY = 'mwan3-newpolicy/';
    TEMPLATE_MWAN3_NEWMEMBER = 'mwan3-newmember/';
    TEMPLATE_IPSEC_REMOTE = 'ipsec-remote/';
    TEMPLATE_IPSEC_TUNNEL = 'ipsec-tunnel/';
    TEMPLATE_VLAN = 'vlan/';
    TEMPLATE_NAT = 'nat/';
    TEMPLATE_QOS_INTERFACE = 'qos-interface/';
    TEMPLATE_QOS_RULE = 'qos-rule/';
    TEMPLATE_QOS_CLASSGROUP = 'qos-classgroup/';
    TEMPLATE_QOS_CLASSES = 'qos-classes/';
    TEMPLATE_DHCP = 'dhcp/';
    TEMPLATE_DHCP_ODHCPD = 'dhcp-odhcpd/';
    TEMPLATE_DHCP_DNSMASQ = 'dhcp-dnsmasq/';
    TEMPLATE_COPY = 'copy/';
    TEMPLATE_BACKUP = 'backup/';
    TEMPLATE_REBOOT = 'reboot/';
    TEMPLATE_SNMPD_SYSTEM = 'snmpd-system/';
    TEMPLATE_SNMPD_COM2SEC = 'snmpd-com2sec/';
    TEMPLATE_SNMPD_USER = 'snmpd-user/';
    TEMPLATE_SYSTEM = 'system/';
    TEMPLATE_SYSTEM_NTP = 'system-ntp/';
    TEMPLATE_DROPBEAR = 'ssh/';

    signupUrl = this.endpointUrl + 'api/config/signup/';
    endpoint2 = this.endpointUrl + '/api-token-auth/';

    SEVER_ADDRESS = this.endpointUrl;

    API_URL = `${this.SEVER_ADDRESS}api/`;
    CONFIG_URL = `${this.API_URL}config/`;
    DEVICE_LIST_URL = `${this.CONFIG_URL}devicelist/`;
    DEVICE_URL = `${this.CONFIG_URL}device/`;

    CREATE_NEW_DEVICE_URL = `${this.CONFIG_URL}devicelist`;
    UPDATE_CLAIM_STATUS_URL = `${this.CONFIG_URL}claimstatus/`;
    ADMIN_DASH_URL = `${this.CONFIG_URL}admin-dashboard`;
    /**
     * Secondary URLs
     * These URLs will have the device/deviceId/ as primary URL
     */

    //  INTERFACE_URL = `${this.CONFIG_URL}/interface/`;
    TENANT_LIST_URL = `${this.CONFIG_URL}tenant/`;
    SD_WAN_MWAN3_RULE_URL = `mwan3-rule/`;
    SD_WAN_MWAN3_INTERFACE_URL = `mwan3-interface/`;
    SD_WAN_MWAN3_MEMBER_URL = `mwan3-member/`;
    SD_WAN_NEW_MWAN3_MEMBER_URL = `mwan3-newmember/`;
    SD_WAN_MWAN3_POLICY_URL = `mwan3-policy/`;
    SD_WAN_MWAN3_NewPOLICY_URL = `mwan3-newpolicy/`;
    SD_WAN_MWAN3_CONFIGS_URL = `mwan3-configs/`;

    LAN_STATUS_FOR_DEVICE_OVERVIEW = `lan-status/`;
    WAN_STATUS_FOR_DEVICE_OVERVIEW = `wan-status/`;
    ADD_TEMPLATE_URL = 'addtemplate/';
    ROUTE_URL = 'routes/';
    URL_TRACKING = 'url-tracking/';
    USAGE_STATUS = 'bandwidth-data/';

    // UNCLAIM
    UNCLAIM = `device-unclaim/`;

    // CONFIGURE
    V_LAN_INTERFACE_URL = `interface/`;
    VLAN_SWITCH_URL = `switch/`;
    STATIC_ROUTE_URL = `routes/`;
    IP_SEC_POLICIES_URL = 'ipsec-tunnel/';
    QOS_INTERFACE = 'qos-interface/';
    QOS_RULES = 'qos-rule/';
    QOS_CLASSGROUP = 'qos-classgroup/';
    QOS_CLASSES = 'qos-classes/';
    CATEGORY_FILTER = 'content-category/';
    URL_BLOCKING = 'content-url/';
    AUTO_VPN = 'autovpn/';
    // utils
    QOS_CONFIGS = 'qos-configs/';

    // SD-WAN STATUS URLS
    SYSTEM_INFORMATION_URL = `system-info/`;
    SYSTEM_BOARD_INFORMATION_URL = `system-board/`;
    ARP_STATUS_URL = `arp-status/`;
    INTERFACE_STATUS_URL = `interface-status/`;
    DHCP_STATUS_URL = `dhcp-leases/`;
    ROUTE_STATUS_URL = `route-status/`;
    IPSEC_STATUS_URL = `ipsec-status/`;
    LINK_BONDING_STATUS_URL = `lb-status/`;
    MWAN_STATUS_URL = `mwan3-status/`;
    DEVICE_STATUS_URL = `devicestatusall/`;
    FIREWALL_STATUS = `firewall-status/`;
    IPSET_STATUS = `ipset-status/`;
    GRAFANA_INTERFACE_STATUS = `grafana-interface/`;
    GRAFANA_PING_STATUS = `grafana-ping/`;
    URLFILTER_STATUS = `urlfilter-status/`;
    ELK_LOGS = `logs/`;

    VRRPTEMPLATE_INTERFACE_URL = `vrrp/`;
    NETFLOW_URL = `netflow/`;
    SNMP_URL = `snmp/`;
    NTP = `system-ntp/`;
    ACTIVESESSIONS_TENANT_URL = `active-sessions-tenant/`;
    ACTIVESESSIONS_DEVICE_URL = `active-sessions-device/`;
    LOGGING = `system/`;
    DROPBEAR = `dropbear/`;
    ENABLE_CONFIGS = `enable-configs/`;
    GRAFANA_ALERTS = `grafana-alert/`;
    NOTIFICATION = `notifications/`;
    LOGS_URL = `logs`;
}

export let SEVER_ADDRESS;
export const NO_CONFIGURATION_MESSAGE = `No Configurations added`;
export const NO_STATUS_MESSAGE = `Status Not Available`;
export const CHART_COLORS = [
    '#0078be',
    '#00b3f0',
    '#6801af',
    '#bb64ff',
    '#ff560b',
    '#ffa608',
    '#298c00',
    '#a9ce21',
    '#58595b',
    '#939598',
];

const getEndpoint = () => {
    const subdomain = location.href.split('//')[1].split('.')[0];
    switch (subdomain) {
        case 'demo': {
            return `https://poc.cosgridnetworks.in/`;
        }
        case 'idp': {
            return `https://poc.cosgridnetworks.in/`;
        }
        case 'cosgrid': {
            return `https://cosgridnetworks.in/`;
        }
        default: {
            return `https://poc.cosgridnetworks.in/`;
        }
    }
};

export const getEndpointType = () => {
    const domain = location.href.split('//')[1].split('.')[0];
    return domain;
};
