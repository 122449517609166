import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { UserService, GlobalService } from 'app/shared/services/core';
import { AlertService, HttpRequestCancelService, StorageService } from 'app/shared/services/misc';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { Observable, throwError, of, timer } from 'rxjs';
import { catchError, delay, concat, retryWhen, tap, timeout, takeUntil, take, mergeMap } from 'rxjs/operators';
// import { tokenNotExpired } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalConstants } from 'app/configs/constants';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
    jwtHelper = new JwtHelperService();
    requestTimeout = 10000;
    retryMaxAttempts = 3;
    constructor(
        private user: UserService,
        private alert: AlertService,
        private httpCancel: HttpRequestCancelService,
        private global: GlobalService,
        private router: Router,
        private storage: StorageService,
        private auth: AuthenticationService,
        private globalConstants: GlobalConstants,
        private encryptedLocalStorageService: EncryptedLocalStorageService, // public jwtHelper: JwtHelperService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.user.getToken()}`,
            },
        });

        return next.handle(request).pipe(
            tap((evt) => {
                if (evt instanceof HttpResponse) {
                    this.alert.dismissSlowInternetSnack(); // dismiss the loading snack if a successfull request happens
                }
            }),
            timeout(this.requestTimeout),
            delay(200), // delay to avoid tables flashing on user
            retryWhen((error) => {
                return error.pipe(
                    mergeMap((error: any) => {
                        // Only retry when it is a timeout error and if the call is not for tenant info and device list
                        if (error.name === 'TimeoutError') {
                            if (request.url.includes('/logout/')) {
                                return throwError({ error: { detail: 'doNothing' } }); // if the user logs out and due to slow internet, interceptor returns timeout error and it will start retrying and show the slowInternet snack
                            } else if (this.isConnectionCheckUrl(request.url)) {
                                this.alert.showSlowInternet();
                                return of(error.status).pipe(delay(1000));
                            }
                        }
                        return throwError(error);
                    }),
                    take(2),
                    concat(throwError({ error: { detail: 'No Connectivity' } })),
                );
            }),
            catchError((error) => {
                if (error.name === 'TimeoutError' && this.isConnectionCheckUrl(request.url)) {
                    this.alert.showSlowInternet();
                } else if (error.error.detail) {
                    this.handleErrorDetail(error.error.detail);
                } else {
                    this.handleUnknownError();
                }
                return throwError(error);
            }),
            takeUntil(this.httpCancel.onCancelPendingRequests()),
        );
    }
    private handleErrorDetail(detail: string): void {
        switch (detail) {
            case 'Given token not valid for any token type':
                this.handleInvalidToken();
                break;
            case 'No Connectivity':
                this.alert.dismissableSnack('No Connectivity detected', 'DISMISS');
                break;
            case 'doNothing':
                // Do nothing as specified
                break;
            default:
                this.alert.snack(detail, 'OK');
                break;
        }
    }

    private handleInvalidToken(): void {
        if (this.storage.getRememberMe()) {
            const refreshToken = this.encryptedLocalStorageService.getItem('refresh');
            if (!this.jwtHelper.isTokenExpired(refreshToken)) {
                this.global.refresh(this.router.url); // Refresh the token if the refresh token is not expired
            } else {
                this.showSessionExpiredAlert();
            }
        } else {
            this.showSessionExpiredAlert();
        }
    }

    private showSessionExpiredAlert(): void {
        this.alert.snack("Your session has expired, Please Login Again", 'OK')
            .afterDismissed()
            .subscribe(() => {
                this.auth.logout();
            });
    }

    private handleUnknownError(): void {
        if (navigator.onLine) {
            this.alert.snack('Server not responding', 'OK');
        }
        // No else block needed as offline case is already handled
    }

    excludeUrls(request) {
        const excludedUrls = [
            '/tenant-info/',
            '/devicelist/',
            this.globalConstants.STATUS_FIREWALL_STATUS,
            this.globalConstants.STATUS_URL_TRACKING,
            this.globalConstants.DEVICE_IPSEC_REFRESH,
        ];
        const isPresent = excludedUrls.find((url) => {
            return request.url.includes(url);
        });
        return !isPresent;
    }

    isConnectionCheckUrl(url: string) {
        return url.includes('cosgrid-com-mail');
    }

    retryAfterDelay() {
        return retryWhen((errors) => {
            return errors.pipe(
                mergeMap((err, count) => {
                    // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
                    if (count === this.retryMaxAttempts) {
                        return throwError(err);
                    }
                    return of(err).pipe(
                        tap((error) => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
                        mergeMap(() => timer(this.requestTimeout)),
                    );
                }),
            );
        });
    }
}
