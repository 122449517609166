<div class="float-right mr-3 ml-3" style="margin-bottom: 10px;">
    <button
        type="button"
        *ngIf="isSelected"
        (click)="callDelete()"
        style="border-radius: 5px;"
        class="btn btn-outline-danger"
    >
        <i class="fa fa-minus-circle" aria-hidden="true"></i> &nbsp; Delete
        <i *ngIf="requestLoading" class="fas fa-circle-notch fa-spin"></i>
    </button>
    <button
        type="button"
        *ngIf="isSelected"
        (click)="callEdit()"
        style="border-radius: 5px;"
        class="btn btn-outline-info"
    >
        <i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Edit
    </button>
    <button type="button" (click)="callAdd()" style="border-radius: 5px;" class="btn btn-outline-info add">
        <i class="fa fa-plus-circle" aria-hidden="true"></i> &nbsp;
        {{ noRows ? 'Create' : 'Add' }}
    </button>
</div>
