import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonDataService {

  private countryCode = [
    {
      "name": "Afghanistan",
      "code": "AF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-af\"></span><span>Afghanistan</span>"
    },
    {
      "name": "Åland Islands",
      "code": "AX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ax\"></span><span>Åland Islands</span>"
    },
    {
      "name": "Albania",
      "code": "AL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-al\"></span><span>Albania</span>"
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dz\"></span><span>Algeria</span>"
    },
    {
      "name": "American Samoa",
      "code": "AS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-as\"></span><span>American Samoa</span>"
    },
    {
      "name": "AndorrA",
      "code": "AD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ad\"></span><span>AndorrA</span>"
    },
    {
      "name": "Angola",
      "code": "AO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ao\"></span><span>Angola</span>"
    },
    {
      "name": "Anguilla",
      "code": "AI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ai\"></span><span>Anguilla</span>"
    },
    {
      "name": "Antarctica",
      "code": "AQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-aq\"></span><span>Antarctica</span>"
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ag\"></span><span>Antigua and Barbuda</span>"
    },
    {
      "name": "Argentina",
      "code": "AR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ar\"></span><span>Argentina</span>"
    },
    {
      "name": "Armenia",
      "code": "AM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-am\"></span><span>Armenia</span>"
    },
    {
      "name": "Aruba",
      "code": "AW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-aw\"></span><span>Aruba</span>"
    },
    {
      "name": "Australia",
      "code": "AU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-au\"></span><span>Australia</span>"
    },
    {
      "name": "Austria",
      "code": "AT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-at\"></span><span>Austria</span>"
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-az\"></span><span>Azerbaijan</span>"
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bs\"></span><span>Bahamas</span>"
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bh\"></span><span>Bahrain</span>"
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bd\"></span><span>Bangladesh</span>"
    },
    {
      "name": "Barbados",
      "code": "BB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bb\"></span><span>Barbados</span>"
    },
    {
      "name": "Belarus",
      "code": "BY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-by\"></span><span>Belarus</span>"
    },
    {
      "name": "Belgium",
      "code": "BE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-be\"></span><span>Belgium</span>"
    },
    {
      "name": "Belize",
      "code": "BZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bz\"></span><span>Belize</span>"
    },
    {
      "name": "Benin",
      "code": "BJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bj\"></span><span>Benin</span>"
    },
    {
      "name": "Bermuda",
      "code": "BM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bm\"></span><span>Bermuda</span>"
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bt\"></span><span>Bhutan</span>"
    },
    {
      "name": "Bolivia",
      "code": "BO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bo\"></span><span>Bolivia</span>"
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ba\"></span><span>Bosnia and Herzegovina</span>"
    },
    {
      "name": "Botswana",
      "code": "BW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bw\"></span><span>Botswana</span>"
    },
    {
      "name": "Bouvet Island",
      "code": "BV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bv\"></span><span>Bouvet Island</span>"
    },
    {
      "name": "Brazil",
      "code": "BR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-br\"></span><span>Brazil</span>"
    },
    {
      "name": "British Indian Ocean Territory",
      "code": "IO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-io\"></span><span>British Indian Ocean Territory</span>"
    },
    {
      "name": "Brunei Darussalam",
      "code": "BN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bn\"></span><span>Brunei Darussalam</span>"
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bg\"></span><span>Bulgaria</span>"
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bf\"></span><span>Burkina Faso</span>"
    },
    {
      "name": "Burundi",
      "code": "BI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bi\"></span><span>Burundi</span>"
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kh\"></span><span>Cambodia</span>"
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cm\"></span><span>Cameroon</span>"
    },
    {
      "name": "Canada",
      "code": "CA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ca\"></span><span>Canada</span>"
    },
    {
      "name": "Cape Verde",
      "code": "CV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cv\"></span><span>Cape Verde</span>"
    },
    {
      "name": "Cayman Islands",
      "code": "KY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ky\"></span><span>Cayman Islands</span>"
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cf\"></span><span>Central African Republic</span>"
    },
    {
      "name": "Chad",
      "code": "TD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-td\"></span><span>Chad</span>"
    },
    {
      "name": "Chile",
      "code": "CL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cl\"></span><span>Chile</span>"
    },
    {
      "name": "China",
      "code": "CN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cn\"></span><span>China</span>"
    },
    {
      "name": "Christmas Island",
      "code": "CX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cx\"></span><span>Christmas Island</span>"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "code": "CC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cc\"></span><span>Cocos (Keeling) Islands</span>"
    },
    {
      "name": "Colombia",
      "code": "CO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-co\"></span><span>Colombia</span>"
    },
    {
      "name": "Comoros",
      "code": "KM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-km\"></span><span>Comoros</span>"
    },
    {
      "name": "Congo",
      "code": "CG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cg\"></span><span>Congo</span>"
    },
    {
      "name": "Congo, The Democratic Republic of the",
      "code": "CD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cd\"></span><span>Congo, The Democratic Republic of the</span>"
    },
    {
      "name": "Cook Islands",
      "code": "CK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ck\"></span><span>Cook Islands</span>"
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cr\"></span><span>Costa Rica</span>"
    },
    {
      "name": "Cote D'Ivoire",
      "code": "CI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ci\"></span><span>Cote D'Ivoire</span>"
    },
    {
      "name": "Croatia",
      "code": "HR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hr\"></span><span>Croatia</span>"
    },
    {
      "name": "Cuba",
      "code": "CU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cu\"></span><span>Cuba</span>"
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cy\"></span><span>Cyprus</span>"
    },
    {
      "name": "Czech Republic",
      "code": "CZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cz\"></span><span>Czech Republic</span>"
    },
    {
      "name": "Denmark",
      "code": "DK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dk\"></span><span>Denmark</span>"
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dj\"></span><span>Djibouti</span>"
    },
    {
      "name": "Dominica",
      "code": "DM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dm\"></span><span>Dominica</span>"
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-do\"></span><span>Dominican Republic</span>"
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ec\"></span><span>Ecuador</span>"
    },
    {
      "name": "Egypt",
      "code": "EG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-eg\"></span><span>Egypt</span>"
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sv\"></span><span>El Salvador</span>"
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gq\"></span><span>Equatorial Guinea</span>"
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-er\"></span><span>Eritrea</span>"
    },
    {
      "name": "Estonia",
      "code": "EE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ee\"></span><span>Estonia</span>"
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-et\"></span><span>Ethiopia</span>"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fk\"></span><span>Falkland Islands (Malvinas)</span>"
    },
    {
      "name": "Faroe Islands",
      "code": "FO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fo\"></span><span>Faroe Islands</span>"
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fj\"></span><span>Fiji</span>"
    },
    {
      "name": "Finland",
      "code": "FI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fi\"></span><span>Finland</span>"
    },
    {
      "name": "France",
      "code": "FR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fr\"></span><span>France</span>"
    },
    {
      "name": "French Guiana",
      "code": "GF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gf\"></span><span>French Guiana</span>"
    },
    {
      "name": "French Polynesia",
      "code": "PF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pf\"></span><span>French Polynesia</span>"
    },
    {
      "name": "French Southern Territories",
      "code": "TF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tf\"></span><span>French Southern Territories</span>"
    },
    {
      "name": "Gabon",
      "code": "GA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ga\"></span><span>Gabon</span>"
    },
    {
      "name": "Gambia",
      "code": "GM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gm\"></span><span>Gambia</span>"
    },
    {
      "name": "Georgia",
      "code": "GE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ge\"></span><span>Georgia</span>"
    },
    {
      "name": "Germany",
      "code": "DE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-de\"></span><span>Germany</span>"
    },
    {
      "name": "Ghana",
      "code": "GH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gh\"></span><span>Ghana</span>"
    },
    {
      "name": "Gibraltar",
      "code": "GI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gi\"></span><span>Gibraltar</span>"
    },
    {
      "name": "Greece",
      "code": "GR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gr\"></span><span>Greece</span>"
    },
    {
      "name": "Greenland",
      "code": "GL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gl\"></span><span>Greenland</span>"
    },
    {
      "name": "Grenada",
      "code": "GD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gd\"></span><span>Grenada</span>"
    },
    {
      "name": "Guadeloupe",
      "code": "GP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gp\"></span><span>Guadeloupe</span>"
    },
    {
      "name": "Guam",
      "code": "GU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gu\"></span><span>Guam</span>"
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gt\"></span><span>Guatemala</span>"
    },
    {
      "name": "Guernsey",
      "code": "GG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gg\"></span><span>Guernsey</span>"
    },
    {
      "name": "Guinea",
      "code": "GN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gn\"></span><span>Guinea</span>"
    },
    {
      "name": "Guinea-Bissau",
      "code": "GW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gw\"></span><span>Guinea-Bissau</span>"
    },
    {
      "name": "Guyana",
      "code": "GY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gy\"></span><span>Guyana</span>"
    },
    {
      "name": "Haiti",
      "code": "HT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ht\"></span><span>Haiti</span>"
    },
    {
      "name": "Heard Island and Mcdonald Islands",
      "code": "HM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hm\"></span><span>Heard Island and Mcdonald Islands</span>"
    },
    {
      "name": "Holy See (Vatican City State)",
      "code": "VA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-va\"></span><span>Holy See (Vatican City State)</span>"
    },
    {
      "name": "Honduras",
      "code": "HN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hn\"></span><span>Honduras</span>"
    },
    {
      "name": "Hong Kong",
      "code": "HK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hk\"></span><span>Hong Kong</span>"
    },
    {
      "name": "Hungary",
      "code": "HU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hu\"></span><span>Hungary</span>"
    },
    {
      "name": "Iceland",
      "code": "IS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-is\"></span><span>Iceland</span>"
    },
    {
      "name": "India",
      "code": "IN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-in\"></span><span>India</span>"
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-id\"></span><span>Indonesia</span>"
    },
    {
      "name": "Iran, Islamic Republic Of",
      "code": "IR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ir\"></span><span>Iran, Islamic Republic Of</span>"
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-iq\"></span><span>Iraq</span>"
    },
    {
      "name": "Ireland",
      "code": "IE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ie\"></span><span>Ireland</span>"
    },
    {
      "name": "Isle of Man",
      "code": "IM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-im\"></span><span>Isle of Man</span>"
    },
    {
      "name": "Israel",
      "code": "IL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-il\"></span><span>Israel</span>"
    },
    {
      "name": "Italy",
      "code": "IT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-it\"></span><span>Italy</span>"
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jm\"></span><span>Jamaica</span>"
    },
    {
      "name": "Japan",
      "code": "JP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jp\"></span><span>Japan</span>"
    },
    {
      "name": "Jersey",
      "code": "JE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-je\"></span><span>Jersey</span>"
    },
    {
      "name": "Jordan",
      "code": "JO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jo\"></span><span>Jordan</span>"
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kz\"></span><span>Kazakhstan</span>"
    },
    {
      "name": "Kenya",
      "code": "KE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ke\"></span><span>Kenya</span>"
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ki\"></span><span>Kiribati</span>"
    },
    {
      "name": "Korea, Democratic People'S Republic of",
      "code": "KP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kp\"></span><span>Korea, Democratic People'S Republic of</span>"
    },
    {
      "name": "Korea, Republic of",
      "code": "KR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kr\"></span><span>Korea, Republic of</span>"
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kw\"></span><span>Kuwait</span>"
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kg\"></span><span>Kyrgyzstan</span>"
    },
    {
      "name": "Lao People'S Democratic Republic",
      "code": "LA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-la\"></span><span>Lao People'S Democratic Republic</span>"
    },
    {
      "name": "Latvia",
      "code": "LV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lv\"></span><span>Latvia</span>"
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lb\"></span><span>Lebanon</span>"
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ls\"></span><span>Lesotho</span>"
    },
    {
      "name": "Liberia",
      "code": "LR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lr\"></span><span>Liberia</span>"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "code": "LY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ly\"></span><span>Libyan Arab Jamahiriya</span>"
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-li\"></span><span>Liechtenstein</span>"
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lt\"></span><span>Lithuania</span>"
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lu\"></span><span>Luxembourg</span>"
    },
    {
      "name": "Macao",
      "code": "MO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mo\"></span><span>Macao</span>"
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "code": "MK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mk\"></span><span>Macedonia, The Former Yugoslav Republic of</span>"
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mg\"></span><span>Madagascar</span>"
    },
    {
      "name": "Malawi",
      "code": "MW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mw\"></span><span>Malawi</span>"
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-my\"></span><span>Malaysia</span>"
    },
    {
      "name": "Maldives",
      "code": "MV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mv\"></span><span>Maldives</span>"
    },
    {
      "name": "Mali",
      "code": "ML",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ml\"></span><span>Mali</span>"
    },
    {
      "name": "Malta",
      "code": "MT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mt\"></span><span>Malta</span>"
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mh\"></span><span>Marshall Islands</span>"
    },
    {
      "name": "Martinique",
      "code": "MQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mq\"></span><span>Martinique</span>"
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mr\"></span><span>Mauritania</span>"
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mu\"></span><span>Mauritius</span>"
    },
    {
      "name": "Mayotte",
      "code": "YT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-yt\"></span><span>Mayotte</span>"
    },
    {
      "name": "Mexico",
      "code": "MX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mx\"></span><span>Mexico</span>"
    },
    {
      "name": "Micronesia, Federated States of",
      "code": "FM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fm\"></span><span>Micronesia, Federated States of</span>"
    },
    {
      "name": "Moldova, Republic of",
      "code": "MD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-md\"></span><span>Moldova, Republic of</span>"
    },
    {
      "name": "Monaco",
      "code": "MC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mc\"></span><span>Monaco</span>"
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mn\"></span><span>Mongolia</span>"
    },
    {
      "name": "Montserrat",
      "code": "MS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ms\"></span><span>Montserrat</span>"
    },
    {
      "name": "Morocco",
      "code": "MA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ma\"></span><span>Morocco</span>"
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mz\"></span><span>Mozambique</span>"
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mm\"></span><span>Myanmar</span>"
    },
    {
      "name": "Namibia",
      "code": "NA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-na\"></span><span>Namibia</span>"
    },
    {
      "name": "Nauru",
      "code": "NR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nr\"></span><span>Nauru</span>"
    },
    {
      "name": "Nepal",
      "code": "NP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-np\"></span><span>Nepal</span>"
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nl\"></span><span>Netherlands</span>"
    },
    {
      "name": "Netherlands Antilles",
      "code": "AN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-an\"></span><span>Netherlands Antilles</span>"
    },
    {
      "name": "New Caledonia",
      "code": "NC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nc\"></span><span>New Caledonia</span>"
    },
    {
      "name": "New Zealand",
      "code": "NZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nz\"></span><span>New Zealand</span>"
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ni\"></span><span>Nicaragua</span>"
    },
    {
      "name": "Niger",
      "code": "NE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ne\"></span><span>Niger</span>"
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ng\"></span><span>Nigeria</span>"
    },
    {
      "name": "Niue",
      "code": "NU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nu\"></span><span>Niue</span>"
    },
    {
      "name": "Norfolk Island",
      "code": "NF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nf\"></span><span>Norfolk Island</span>"
    },
    {
      "name": "Northern Mariana Islands",
      "code": "MP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mp\"></span><span>Northern Mariana Islands</span>"
    },
    {
      "name": "Norway",
      "code": "NO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-no\"></span><span>Norway</span>"
    },
    {
      "name": "Oman",
      "code": "OM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-om\"></span><span>Oman</span>"
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pk\"></span><span>Pakistan</span>"
    },
    {
      "name": "Palau",
      "code": "PW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pw\"></span><span>Palau</span>"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "code": "PS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ps\"></span><span>Palestinian Territory, Occupied</span>"
    },
    {
      "name": "Panama",
      "code": "PA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pa\"></span><span>Panama</span>"
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pg\"></span><span>Papua New Guinea</span>"
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-py\"></span><span>Paraguay</span>"
    },
    {
      "name": "Peru",
      "code": "PE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pe\"></span><span>Peru</span>"
    },
    {
      "name": "Philippines",
      "code": "PH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ph\"></span><span>Philippines</span>"
    },
    {
      "name": "Pitcairn",
      "code": "PN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pn\"></span><span>Pitcairn</span>"
    },
    {
      "name": "Poland",
      "code": "PL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pl\"></span><span>Poland</span>"
    },
    {
      "name": "Portugal",
      "code": "PT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pt\"></span><span>Portugal</span>"
    },
    {
      "name": "Puerto Rico",
      "code": "PR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pr\"></span><span>Puerto Rico</span>"
    },
    {
      "name": "Qatar",
      "code": "QA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-qa\"></span><span>Qatar</span>"
    },
    {
      "name": "Reunion",
      "code": "RE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-re\"></span><span>Reunion</span>"
    },
    {
      "name": "Romania",
      "code": "RO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ro\"></span><span>Romania</span>"
    },
    {
      "name": "Russian Federation",
      "code": "RU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ru\"></span><span>Russian Federation</span>"
    },
    {
      "name": "RWANDA",
      "code": "RW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-rw\"></span><span>RWANDA</span>"
    },
    {
      "name": "Saint Helena",
      "code": "SH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sh\"></span><span>Saint Helena</span>"
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kn\"></span><span>Saint Kitts and Nevis</span>"
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lc\"></span><span>Saint Lucia</span>"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "code": "PM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pm\"></span><span>Saint Pierre and Miquelon</span>"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vc\"></span><span>Saint Vincent and the Grenadines</span>"
    },
    {
      "name": "Samoa",
      "code": "WS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ws\"></span><span>Samoa</span>"
    },
    {
      "name": "San Marino",
      "code": "SM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sm\"></span><span>San Marino</span>"
    },
    {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-st\"></span><span>Sao Tome and Principe</span>"
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sa\"></span><span>Saudi Arabia</span>"
    },
    {
      "name": "Senegal",
      "code": "SN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sn\"></span><span>Senegal</span>"
    },
    {
      "name": "Serbia and Montenegro",
      "code": "CS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cs\"></span><span>Serbia and Montenegro</span>"
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sc\"></span><span>Seychelles</span>"
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sl\"></span><span>Sierra Leone</span>"
    },
    {
      "name": "Singapore",
      "code": "SG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sg\"></span><span>Singapore</span>"
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sk\"></span><span>Slovakia</span>"
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-si\"></span><span>Slovenia</span>"
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sb\"></span><span>Solomon Islands</span>"
    },
    {
      "name": "Somalia",
      "code": "SO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-so\"></span><span>Somalia</span>"
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-za\"></span><span>South Africa</span>"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "code": "GS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gs\"></span><span>South Georgia and the South Sandwich Islands</span>"
    },
    {
      "name": "Spain",
      "code": "ES",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-es\"></span><span>Spain</span>"
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lk\"></span><span>Sri Lanka</span>"
    },
    {
      "name": "Sudan",
      "code": "SD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sd\"></span><span>Sudan</span>"
    },
    {
      "name": "Suriname",
      "code": "SR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sr\"></span><span>Suriname</span>"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sj\"></span><span>Svalbard and Jan Mayen</span>"
    },
    {
      "name": "Swaziland",
      "code": "SZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sz\"></span><span>Swaziland</span>"
    },
    {
      "name": "Sweden",
      "code": "SE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-se\"></span><span>Sweden</span>"
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ch\"></span><span>Switzerland</span>"
    },
    {
      "name": "Syrian Arab Republic",
      "code": "SY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sy\"></span><span>Syrian Arab Republic</span>"
    },
    {
      "name": "Taiwan, Province of China",
      "code": "TW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tw\"></span><span>Taiwan, Province of China</span>"
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tj\"></span><span>Tajikistan</span>"
    },
    {
      "name": "Tanzania, United Republic of",
      "code": "TZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tz\"></span><span>Tanzania, United Republic of</span>"
    },
    {
      "name": "Thailand",
      "code": "TH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-th\"></span><span>Thailand</span>"
    },
    {
      "name": "Timor-Leste",
      "code": "TL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tl\"></span><span>Timor-Leste</span>"
    },
    {
      "name": "Togo",
      "code": "TG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tg\"></span><span>Togo</span>"
    },
    {
      "name": "Tokelau",
      "code": "TK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tk\"></span><span>Tokelau</span>"
    },
    {
      "name": "Tonga",
      "code": "TO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-to\"></span><span>Tonga</span>"
    },
    {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tt\"></span><span>Trinidad and Tobago</span>"
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tn\"></span><span>Tunisia</span>"
    },
    {
      "name": "Turkey",
      "code": "TR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tr\"></span><span>Turkey</span>"
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tm\"></span><span>Turkmenistan</span>"
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tc\"></span><span>Turks and Caicos Islands</span>"
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tv\"></span><span>Tuvalu</span>"
    },
    {
      "name": "Uganda",
      "code": "UG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ug\"></span><span>Uganda</span>"
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ua\"></span><span>Ukraine</span>"
    },
    {
      "name": "United Arab Emirates",
      "code": "AE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ae\"></span><span>United Arab Emirates</span>"
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gb\"></span><span>United Kingdom</span>"
    },
    {
      "name": "United States",
      "code": "US",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-us\"></span><span>United States</span>"
    },
    {
      "name": "United States Minor Outlying Islands",
      "code": "UM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-um\"></span><span>United States Minor Outlying Islands</span>"
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-uy\"></span><span>Uruguay</span>"
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-uz\"></span><span>Uzbekistan</span>"
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vu\"></span><span>Vanuatu</span>"
    },
    {
      "name": "Venezuela",
      "code": "VE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ve\"></span><span>Venezuela</span>"
    },
    {
      "name": "Viet Nam",
      "code": "VN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vn\"></span><span>Viet Nam</span>"
    },
    {
      "name": "Virgin Islands, British",
      "code": "VG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vg\"></span><span>Virgin Islands, British</span>"
    },
    {
      "name": "Virgin Islands, U.S.",
      "code": "VI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vi\"></span><span>Virgin Islands, U.S.</span>"
    },
    {
      "name": "Wallis and Futuna",
      "code": "WF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-wf\"></span><span>Wallis and Futuna</span>"
    },
    {
      "name": "Western Sahara",
      "code": "EH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-eh\"></span><span>Western Sahara</span>"
    },
    {
      "name": "Yemen",
      "code": "YE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ye\"></span><span>Yemen</span>"
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-zm\"></span><span>Zambia</span>"
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-zw\"></span><span>Zimbabwe</span>"
    }
  ];

  private timeZones = [
    {
      "value": "Dateline Standard Time",
      "abbr": "DST",
      "offset": -12,
      "isdst": false,
      "text": "(UTC-12:00) International Date Line West",
      "utc": [
        "Etc/GMT+12"
      ]
    },
    {
      "value": "UTC-11",
      "abbr": "U",
      "offset": -11,
      "isdst": false,
      "text": "(UTC-11:00) Coordinated Universal Time-11",
      "utc": [
        "Etc/GMT+11",
        "Pacific/Midway",
        "Pacific/Niue",
        "Pacific/Pago_Pago"
      ]
    },
    {
      "value": "Hawaiian Standard Time",
      "abbr": "HST",
      "offset": -10,
      "isdst": false,
      "text": "(UTC-10:00) Hawaii",
      "utc": [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti"
      ]
    },
    {
      "value": "Alaskan Standard Time",
      "abbr": "AKDT",
      "offset": -8,
      "isdst": true,
      "text": "(UTC-09:00) Alaska",
      "utc": [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat"
      ]
    },
    {
      "value": "Pacific Standard Time (Mexico)",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-08:00) Baja California",
      "utc": [
        "America/Santa_Isabel"
      ]
    },
    {
      "value": "Pacific Daylight Time",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-07:00) Pacific Daylight Time (US & Canada)",
      "utc": [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver"
      ]
    },
    {
      "value": "Pacific Standard Time",
      "abbr": "PST",
      "offset": -8,
      "isdst": false,
      "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
      "utc": [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "PST8PDT"
      ]
    },
    {
      "value": "US Mountain Standard Time",
      "abbr": "UMST",
      "offset": -7,
      "isdst": false,
      "text": "(UTC-07:00) Arizona",
      "utc": [
        "America/Creston",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "America/Whitehorse",
        "Etc/GMT+7"
      ]
    },
    {
      "value": "Mountain Standard Time (Mexico)",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      "utc": [
        "America/Chihuahua",
        "America/Mazatlan"
      ]
    },
    {
      "value": "Mountain Standard Time",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Mountain Time (US & Canada)",
      "utc": [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT"
      ]
    },
    {
      "value": "Central America Standard Time",
      "abbr": "CAST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Central America",
      "utc": [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos"
      ]
    },
    {
      "value": "Central Standard Time",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Central Time (US & Canada)",
      "utc": [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT"
      ]
    },
    {
      "value": "Central Standard Time (Mexico)",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      "utc": [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey"
      ]
    },
    {
      "value": "Canada Central Standard Time",
      "abbr": "CCST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Saskatchewan",
      "utc": [
        "America/Regina",
        "America/Swift_Current"
      ]
    },
    {
      "value": "SA Pacific Standard Time",
      "abbr": "SPST",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Bogota, Lima, Quito",
      "utc": [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5"
      ]
    },
    {
      "value": "Eastern Standard Time",
      "abbr": "EST",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Eastern Time (US & Canada)",
      "utc": [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto"
      ]
    },
    {
      "value": "Eastern Daylight Time",
      "abbr": "EDT",
      "offset": -4,
      "isdst": true,
      "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
      "utc": [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto"
      ]
    },
    {
      "value": "US Eastern Standard Time",
      "abbr": "UEDT",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Indiana (East)",
      "utc": [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis"
      ]
    },
    {
      "value": "Venezuela Standard Time",
      "abbr": "VST",
      "offset": -4.5,
      "isdst": false,
      "text": "(UTC-04:30) Caracas",
      "utc": [
        "America/Caracas"
      ]
    },
    {
      "value": "Paraguay Standard Time",
      "abbr": "PYT",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Asuncion",
      "utc": [
        "America/Asuncion"
      ]
    },
    {
      "value": "Atlantic Standard Time",
      "abbr": "ADT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-04:00) Atlantic Time (Canada)",
      "utc": [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda"
      ]
    },
    {
      "value": "Central Brazilian Standard Time",
      "abbr": "CBST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Cuiaba",
      "utc": [
        "America/Campo_Grande",
        "America/Cuiaba"
      ]
    },
    {
      "value": "SA Western Standard Time",
      "abbr": "SWST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      "utc": [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4"
      ]
    },
    {
      "value": "Pacific SA Standard Time",
      "abbr": "PSST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Santiago",
      "utc": [
        "America/Santiago",
        "Antarctica/Palmer"
      ]
    },
    {
      "value": "Newfoundland Standard Time",
      "abbr": "NDT",
      "offset": -2.5,
      "isdst": true,
      "text": "(UTC-03:30) Newfoundland",
      "utc": [
        "America/St_Johns"
      ]
    },
    {
      "value": "E. South America Standard Time",
      "abbr": "ESAST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Brasilia",
      "utc": [
        "America/Sao_Paulo"
      ]
    },
    {
      "value": "Argentina Standard Time",
      "abbr": "AST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Buenos Aires",
      "utc": [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza"
      ]
    },
    {
      "value": "SA Eastern Standard Time",
      "abbr": "SEST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Cayenne, Fortaleza",
      "utc": [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3"
      ]
    },
    {
      "value": "Greenland Standard Time",
      "abbr": "GDT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-03:00) Greenland",
      "utc": [
        "America/Godthab"
      ]
    },
    {
      "value": "Montevideo Standard Time",
      "abbr": "MST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Montevideo",
      "utc": [
        "America/Montevideo"
      ]
    },
    {
      "value": "Bahia Standard Time",
      "abbr": "BST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Salvador",
      "utc": [
        "America/Bahia"
      ]
    },
    {
      "value": "UTC-02",
      "abbr": "U",
      "offset": -2,
      "isdst": false,
      "text": "(UTC-02:00) Coordinated Universal Time-02",
      "utc": [
        "America/Noronha",
        "Atlantic/South_Georgia",
        "Etc/GMT+2"
      ]
    },
    {
      "value": "Mid-Atlantic Standard Time",
      "abbr": "MDT",
      "offset": -1,
      "isdst": true,
      "text": "(UTC-02:00) Mid-Atlantic - Old",
      "utc": []
    },
    {
      "value": "Azores Standard Time",
      "abbr": "ADT",
      "offset": 0,
      "isdst": true,
      "text": "(UTC-01:00) Azores",
      "utc": [
        "America/Scoresbysund",
        "Atlantic/Azores"
      ]
    },
    {
      "value": "Cape Verde Standard Time",
      "abbr": "CVST",
      "offset": -1,
      "isdst": false,
      "text": "(UTC-01:00) Cape Verde Is.",
      "utc": [
        "Atlantic/Cape_Verde",
        "Etc/GMT+1"
      ]
    },
    {
      "value": "Morocco Standard Time",
      "abbr": "MDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Casablanca",
      "utc": [
        "Africa/Casablanca",
        "Africa/El_Aaiun"
      ]
    },
    {
      "value": "UTC",
      "abbr": "UTC",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Coordinated Universal Time",
      "utc": [
        "America/Danmarkshavn",
        "Etc/GMT"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GMT",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "British Summer Time",
      "abbr": "BST",
      "offset": 1,
      "isdst": true,
      "text": "(UTC+01:00) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Dublin, Lisbon",
      "utc": [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon"
      ]
    },
    {
      "value": "Greenwich Standard Time",
      "abbr": "GST",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Monrovia, Reykjavik",
      "utc": [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena"
      ]
    },
    {
      "value": "W. Europe Standard Time",
      "abbr": "WEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      "utc": [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich"
      ]
    },
    {
      "value": "Central Europe Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "utc": [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane"
      ]
    },
    {
      "value": "Romance Standard Time",
      "abbr": "RDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      "utc": [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris"
      ]
    },
    {
      "value": "Central European Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      "utc": [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb"
      ]
    },
    {
      "value": "W. Central Africa Standard Time",
      "abbr": "WCAST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) West Central Africa",
      "utc": [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1"
      ]
    },
    {
      "value": "Namibia Standard Time",
      "abbr": "NST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) Windhoek",
      "utc": [
        "Africa/Windhoek"
      ]
    },
    {
      "value": "GTB Standard Time",
      "abbr": "GDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Athens, Bucharest",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau"
      ]
    },
    {
      "value": "Middle East Standard Time",
      "abbr": "MEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Beirut",
      "utc": [
        "Asia/Beirut"
      ]
    },
    {
      "value": "Egypt Standard Time",
      "abbr": "EST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Cairo",
      "utc": [
        "Africa/Cairo"
      ]
    },
    {
      "value": "Syria Standard Time",
      "abbr": "SDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Damascus",
      "utc": [
        "Asia/Damascus"
      ]
    },
    {
      "value": "E. Europe Standard Time",
      "abbr": "EEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) E. Europe",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kyiv",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhhorod",
        "Europe/Vilnius",
        "Europe/Zaporizhzhia"

      ]
    },
    {
      "value": "South Africa Standard Time",
      "abbr": "SAST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Harare, Pretoria",
      "utc": [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2"
      ]
    },
    {
      "value": "FLE Standard Time",
      "abbr": "FDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      "utc": [
        "Europe/Helsinki",
        "Europe/Kyiv",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhhorod",
        "Europe/Vilnius",
        "Europe/Zaporizhzhia"
      ]
    },
    {
      "value": "Turkey Standard Time",
      "abbr": "TDT",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Istanbul",
      "utc": [
        "Europe/Istanbul"
      ]
    },
    {
      "value": "Israel Standard Time",
      "abbr": "JDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Jerusalem",
      "utc": [
        "Asia/Jerusalem"
      ]
    },
    {
      "value": "Libya Standard Time",
      "abbr": "LST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Tripoli",
      "utc": [
        "Africa/Tripoli"
      ]
    },
    {
      "value": "Jordan Standard Time",
      "abbr": "JST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Amman",
      "utc": [
        "Asia/Amman"
      ]
    },
    {
      "value": "Arabic Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Baghdad",
      "utc": [
        "Asia/Baghdad"
      ]
    },
    {
      "value": "Kaliningrad Standard Time",
      "abbr": "KST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+02:00) Kaliningrad",
      "utc": [
        "Europe/Kaliningrad"
      ]
    },
    {
      "value": "Arab Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Kuwait, Riyadh",
      "utc": [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh"
      ]
    },
    {
      "value": "E. Africa Standard Time",
      "abbr": "EAST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Nairobi",
      "utc": [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte"
      ]
    },
    {
      "value": "Moscow Standard Time",
      "abbr": "MSK",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      "utc": [
        "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk"
      ]
    },
    {
      "value": "Samara Time",
      "abbr": "SAMT",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      "utc": [
        "Europe/Astrakhan",
        "Europe/Samara",
        "Europe/Ulyanovsk"
      ]
    },
    {
      "value": "Iran Standard Time",
      "abbr": "IDT",
      "offset": 4.5,
      "isdst": true,
      "text": "(UTC+03:30) Tehran",
      "utc": [
        "Asia/Tehran"
      ]
    },
    {
      "value": "Arabian Standard Time",
      "abbr": "AST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Abu Dhabi, Muscat",
      "utc": [
        "Asia/Dubai",
        "Asia/Muscat",
        "Etc/GMT-4"
      ]
    },
    {
      "value": "Azerbaijan Standard Time",
      "abbr": "ADT",
      "offset": 5,
      "isdst": true,
      "text": "(UTC+04:00) Baku",
      "utc": [
        "Asia/Baku"
      ]
    },
    {
      "value": "Mauritius Standard Time",
      "abbr": "MST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Port Louis",
      "utc": [
        "Indian/Mahe",
        "Indian/Mauritius",
        "Indian/Reunion"
      ]
    },
    {
      "value": "Georgian Standard Time",
      "abbr": "GET",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Tbilisi",
      "utc": [
        "Asia/Tbilisi"
      ]
    },
    {
      "value": "Caucasus Standard Time",
      "abbr": "CST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Yerevan",
      "utc": [
        "Asia/Yerevan"
      ]
    },
    {
      "value": "Afghanistan Standard Time",
      "abbr": "AST",
      "offset": 4.5,
      "isdst": false,
      "text": "(UTC+04:30) Kabul",
      "utc": [
        "Asia/Kabul"
      ]
    },
    {
      "value": "West Asia Standard Time",
      "abbr": "WAST",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Ashgabat, Tashkent",
      "utc": [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives"
      ]
    },
    {
      "value": "Yekaterinburg Time",
      "abbr": "YEKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Yekaterinburg",
      "utc": [
        "Asia/Yekaterinburg"
      ]
    },
    {
      "value": "Pakistan Standard Time",
      "abbr": "PKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Islamabad, Karachi",
      "utc": [
        "Asia/Karachi"
      ]
    },
    {
      "value": "India Standard Time",
      "abbr": "IST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      "utc": [
        "Asia/Kolkata",
        "Asia/Calcutta"
      ]
    },
    {
      "value": "Sri Lanka Standard Time",
      "abbr": "SLST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Sri Jayawardenepura",
      "utc": [
        "Asia/Colombo"
      ]
    },
    {
      "value": "Nepal Standard Time",
      "abbr": "NST",
      "offset": 5.75,
      "isdst": false,
      "text": "(UTC+05:45) Kathmandu",
      "utc": [
        "Asia/Kathmandu"
      ]
    },
    {
      "value": "Central Asia Standard Time",
      "abbr": "CAST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Nur-Sultan (Astana)",
      "utc": [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos"
      ]
    },
    {
      "value": "Bangladesh Standard Time",
      "abbr": "BST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Dhaka",
      "utc": [
        "Asia/Dhaka",
        "Asia/Thimphu"
      ]
    },
    {
      "value": "Myanmar Standard Time",
      "abbr": "MST",
      "offset": 6.5,
      "isdst": false,
      "text": "(UTC+06:30) Yangon (Rangoon)",
      "utc": [
        "Asia/Rangoon",
        "Indian/Cocos"
      ]
    },
    {
      "value": "SE Asia Standard Time",
      "abbr": "SAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      "utc": [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas"
      ]
    },
    {
      "value": "N. Central Asia Standard Time",
      "abbr": "NCAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Novosibirsk",
      "utc": [
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk"
      ]
    },
    {
      "value": "China Standard Time",
      "abbr": "CST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      "utc": [
        "Asia/Hong_Kong",
        "Asia/Macau",
        "Asia/Shanghai"
      ]
    },
    {
      "value": "North Asia Standard Time",
      "abbr": "NAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Krasnoyarsk",
      "utc": [
        "Asia/Krasnoyarsk"
      ]
    },
    {
      "value": "Singapore Standard Time",
      "abbr": "MPST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Kuala Lumpur, Singapore",
      "utc": [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8"
      ]
    },
    {
      "value": "W. Australia Standard Time",
      "abbr": "WAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Perth",
      "utc": [
        "Antarctica/Casey",
        "Australia/Perth"
      ]
    },
    {
      "value": "Taipei Standard Time",
      "abbr": "TST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Taipei",
      "utc": [
        "Asia/Taipei"
      ]
    },
    {
      "value": "Ulaanbaatar Standard Time",
      "abbr": "UST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Ulaanbaatar",
      "utc": [
        "Asia/Choibalsan",
        "Asia/Ulaanbaatar"
      ]
    },
    {
      "value": "North Asia East Standard Time",
      "abbr": "NAEST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Irkutsk",
      "utc": [
        "Asia/Irkutsk"
      ]
    },
    {
      "value": "Japan Standard Time",
      "abbr": "JST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
      "utc": [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau"
      ]
    },
    {
      "value": "Korea Standard Time",
      "abbr": "KST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Seoul",
      "utc": [
        "Asia/Pyongyang",
        "Asia/Seoul"
      ]
    },
    {
      "value": "Cen. Australia Standard Time",
      "abbr": "CAST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Adelaide",
      "utc": [
        "Australia/Adelaide",
        "Australia/Broken_Hill"
      ]
    },
    {
      "value": "AUS Central Standard Time",
      "abbr": "ACST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Darwin",
      "utc": [
        "Australia/Darwin"
      ]
    },
    {
      "value": "E. Australia Standard Time",
      "abbr": "EAST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Brisbane",
      "utc": [
        "Australia/Brisbane",
        "Australia/Lindeman"
      ]
    },
    {
      "value": "AUS Eastern Standard Time",
      "abbr": "AEST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
      "utc": [
        "Australia/Melbourne",
        "Australia/Sydney"
      ]
    },
    {
      "value": "West Pacific Standard Time",
      "abbr": "WPST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Guam, Port Moresby",
      "utc": [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk"
      ]
    },
    {
      "value": "Tasmania Standard Time",
      "abbr": "TST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Hobart",
      "utc": [
        "Australia/Currie",
        "Australia/Hobart"
      ]
    },
    {
      "value": "Yakutsk Standard Time",
      "abbr": "YST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Yakutsk",
      "utc": [
        "Asia/Chita",
        "Asia/Khandyga",
        "Asia/Yakutsk"
      ]
    },
    {
      "value": "Central Pacific Standard Time",
      "abbr": "CPST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Solomon Is., New Caledonia",
      "utc": [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape"
      ]
    },
    {
      "value": "Vladivostok Standard Time",
      "abbr": "VST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Vladivostok",
      "utc": [
        "Asia/Sakhalin",
        "Asia/Ust-Nera",
        "Asia/Vladivostok"
      ]
    },
    {
      "value": "New Zealand Standard Time",
      "abbr": "NZST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Auckland, Wellington",
      "utc": [
        "Antarctica/McMurdo",
        "Pacific/Auckland"
      ]
    },
    {
      "value": "UTC+12",
      "abbr": "U",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Coordinated Universal Time+12",
      "utc": [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis"
      ]
    },
    {
      "value": "Fiji Standard Time",
      "abbr": "FST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Fiji",
      "utc": [
        "Pacific/Fiji"
      ]
    },
    {
      "value": "Magadan Standard Time",
      "abbr": "MST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Magadan",
      "utc": [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk"
      ]
    },
    {
      "value": "Kamchatka Standard Time",
      "abbr": "KDT",
      "offset": 13,
      "isdst": true,
      "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      "utc": [
        "Asia/Kamchatka"
      ]
    },
    {
      "value": "Tonga Standard Time",
      "abbr": "TST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Nuku'alofa",
      "utc": [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu"
      ]
    },
    {
      "value": "Samoa Standard Time",
      "abbr": "SST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Samoa",
      "utc": [
        "Pacific/Apia"
      ]
    }
  ]


  constructor() { }

  getCountryDetails() {
    return this.countryCode
  }

  getTimeZoneDetails() {
    return this.timeZones
  }
}
