import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { Component, Input } from '@angular/core';

export interface ITile {
    name: string;
    value: string;
    icon: string;
    color: string;
}

@Component({
    selector: 'cosgrid-tile-group',
    templateUrl: './tile-group.component.html',
    styleUrls: ['./tile-group.component.scss'],
})
export class TileGroupComponent {
    @Input() tiles: ITile[] = [];
    vpnDashboard;
    constructor(private store: Store<CosgridAppStore>) {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
    }
}
