import { CHART_COLORS } from 'app/configs/constants';
import { CustomerListService } from 'app/shared/services/comp';
import { ChartserviceService } from 'app/modules/sd-wan-gateway/sub-modules/configure/services/chartservice.service';
import { Component, OnInit, Input, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import * as Chart from 'chart.js';
// @ts-ignore
import * as anychart from 'anychart';
@Component({
    selector: 'cosgrid-top-devices',
    templateUrl: './top-devices.component.html',
    styleUrls: ['./top-devices.component.scss'],
})
export class TopDevicesComponent implements OnInit {
    @Input() networkChange: boolean;
    colors: any = [
        '#0078be','#00b3f0','#6801af','#bb64ff','#ff560b','#ffa608','#298c00','#a9ce21','#58595b','#939598'
        ];

    topDeviceData: any;
    topDeviceLayout: any;
    topDeviceConf: any;

    isEmpty: Boolean;
    canvas: any;
    ctx: any;
    data: any;
    loading = false;
    myChart;
    renderTime = 100;
    graphConfData = {
        timeduration: 1440,
        top_count: 10,
        tenant_id: this.customerListService.getTenantId(),
        network_id: '',
    };
    sum: String = 'hii';
    flag: Boolean = false;
    flag1: Boolean = false;
    flag2: Boolean = false;
    flag3: Boolean = false;
    currentChartId = 3;

    constructor(
        private service: ChartserviceService,
        private customerListService: CustomerListService,
        private renderer: Renderer2,
        private el: ElementRef,
    ) {}
    ngOnInit(): void {
        Chart.pluginService.register({
            beforeDraw: function (chart: any) {
                if (chart.config.options.elements.center && chart.config.type === 'doughnut') {
                    var ctx = chart.chart.ctx;
                    var centerConfig = chart.config.options.elements.center;
                    var fontStyle = centerConfig.fontStyle || 'Arial';
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var maxFontSize = centerConfig.maxFontSize || 25;
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                    ctx.font = '17px ' + fontStyle;
                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = chart.innerRadius * 2;

                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    var minFontSize = centerConfig.minFontSize;
                    var lineHeight = centerConfig.lineHeight || 25;
                    var wrapText = false;

                    if (minFontSize === undefined) {
                        minFontSize = 20;
                    }

                    if (minFontSize && fontSizeToUse < minFontSize) {
                        fontSizeToUse = minFontSize;
                        wrapText = true;
                    }

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                    var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                    ctx.font = fontSizeToUse + 'px ' + fontStyle;
                    ctx.fillStyle = color;

                    if (!wrapText) {
                        ctx.fillText(txt, centerX, centerY);
                        return;
                    }

                    var words = txt.split(' ');
                    var line = '';
                    var lines = [];

                    for (var n = 0; n < words.length; n++) {
                        var testLine = line + words[n] + ' ';
                        var metrics = ctx.measureText(testLine);
                        var testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                        } else {
                            line = testLine;
                        }
                    }

                    centerY -= (lines.length / 2) * lineHeight;

                    for (var n = 0; n < lines.length; n++) {
                        ctx.fillText(lines[n], centerX, centerY);
                        centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
            },
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        try {
            this.graphConfData.network_id = changes.networkChange.currentValue.id;
            this.changeChart();
        } catch (err) {}
    }

    changeChart() {
        if (this.currentChartId == 1) {
            this.getTopDevicesData();
        } else if (this.currentChartId == 2) {
            this.getTopProto();
        } else if (this.currentChartId == 3) {
            this.getTopApplications();
        } else if (this.currentChartId == 4) {
            this.getTopUsers();
        } else if (this.currentChartId == 5) {
            this.getTopSources();
        } else if (this.currentChartId == 6) {
            this.getTopDestinations();
        }
    }

    getTopApplications() {
        this.loading = true;
        this.service.getTopTenantApplications(this.graphConfData).subscribe(
            (data: any) => {
                this.loading = false;
                if (data.length < 1) {
                    this.isEmpty = true;
                } else {
                    this.isEmpty = false;
                    setTimeout(() => {
                        this.renderTopApplicationsChart(data, 'tenantGraph'); // after setting isEmpty to false, it will take a lil time to render in the dom
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading = false;
            },
        );
    }

    getTopUsers() {
        this.loading = true;
        this.service.getTopTenantUsers(this.graphConfData).subscribe((data: any) => {
            this.loading = false;
            if (data.length < 1) {
                this.isEmpty = true;
            } else {
                this.isEmpty = false;
                setTimeout(() => {
                    this.renderTopUsersChart(data, 'tenantGraph'); // after setting isEmpty to false, it will take a lil time to render in the dom
                }, this.renderTime);
            }
        }),
            (err) => {
                this.loading = false;
            };
    }

    getTopDevicesData() {
        this.loading = true;

        this.service.getTopDevicesData(this.graphConfData).subscribe((data: any) => {
            if (data.length < 1) {
                this.isEmpty = true;
            } else {
                this.isEmpty = false;
                setTimeout(() => {
                    this.renderTopDevicesChart(data, 'tenantGraph');
                }, this.renderTime);
            }
        }),
            (err) => {
                this.loading = false;
                console.log(err);
            };
    }

    getTopProto() {
        this.loading = true;
        this.service.getTopTenantProto(this.graphConfData).subscribe(
            (data: any) => {
                this.loading = false;
                if (data.length < 1) {
                    this.isEmpty = true;
                } else {
                    this.isEmpty = false;
                    setTimeout(() => {
                        this.renderTopProtoChart(data, 'tenantGraph');
                    }, this.renderTime);
                }
            },
            (err) => {
                this.loading = false;
            },
        );
    }

    renderTopProtoChart(data: Array<any>, htmlId) {
        let keys = [];
        let values = [];
        let percentage = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });
        keys = data.map((inp) => inp.proto);
        values = data.map((inp) => inp['total']['value']);

        let total = values.reduce((val, acu) => val + acu);

        percentage = values.map((inp) => ((inp / total) * 100).toPrecision(3));
        for (const i in keys) {
            keys[i] = keys[i] + ' ' + percentage[i] + '%';
        }
        total /= 1000000;
        total = Math.round(total);

        if (this.myChart) {
            this.myChart.destroy(); // to destroy previously created chart
        }

        this.sum = total.toString();
        this.canvas = document.getElementById(htmlId);
        this.ctx = this.canvas?.getContext('2d');
        this.myChart = new Chart(this.ctx, {
            type: 'doughnut',
            animation: {
                animateScale: true,
            },
            data: {
                labels: keys,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            '#0078be','#00b3f0','#6801af','#bb64ff','#ff560b','#ffa608','#298c00','#a9ce21','#58595b','#939598'
                            ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                cutoutPercentage: 60,
                elements: {
                    // @ts-ignore
                    center: {
                        text: this.sum + ' MB',
                    },
                },
                legend: {
                    position: 'right',
                    labels: {
                        fontSize: 12,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItems, data) => data.labels[tooltipItems.index] as string,
                    },
                    bodyFontSize: 12,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    }

    renderTopApplicationsChart(data: Array<any>, htmlId) {
        let keys = [];
        let values = [];
        let percentage = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });

        keys = data.map((inp) => inp.port_name);
        values = data.map((inp) => inp['total']['value']);

        let total = values.reduce((val, acu) => val + acu);
        percentage = values.map((inp) => ((inp / total) * 100).toPrecision(3));
        for (const i in keys) {
            keys[i] = keys[i] + ' ' + percentage[i] + '%';
        }
        total /= 1000000;
        total = Math.round(total);
        this.sum = total.toString();
        if (this.myChart) {
            this.myChart.destroy(); // to destroy previously created chart
        }
        this.canvas = document.getElementById(htmlId);
        this.ctx = this.canvas?.getContext('2d');
        this.myChart = new Chart(this.ctx, {
            type: 'doughnut',
            data: {
                labels: keys,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            '#0078be','#00b3f0','#6801af','#bb64ff','#ff560b','#ffa608','#298c00','#a9ce21','#58595b','#939598'
                            ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                cutoutPercentage: 60,
                legend: {
                    position: 'right',
                    labels: {
                        fontSize: 12,
                    },
                },
                elements: {
                    // @ts-ignore
                    center: {
                        text: this.sum + ' MB',
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItems, data) => data.labels[tooltipItems.index] as string,
                    },
                    bodyFontSize: 12,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    }

    renderTopUsersChart(data: Array<any>, htmlId) {
        let flowData = data.map((item) => {
            let temp = item['key'].split('-');
            return {
                from: temp[0],
                to: temp[1],
                weight: item['total']['value'],
                text: item['value'],
                nodeColor: 'red',
            };
        });

        const chart = anychart.sankey();

        chart.data(flowData);

        chart.flow().hovered().labels().enabled(false);
        chart.node().tooltip().format('');
        chart.flow().tooltip().format('value: {%text}\n');
        chart.nodeWidth(90);
        chart.nodePadding(10);
        chart.container('talkers-container');
        chart.draw();

        const creditsDiv = this.el.nativeElement.querySelector('.anychart-credits');
        if (creditsDiv) {
            this.renderer.setStyle(creditsDiv, 'display', 'none');
        }
    }

    renderTopDevicesChart(data: Array<any>, htmlId) {
        if (this.currentChartId == 4) {
            let keys = [];
            let values = [];
            const tooltips = [];

            let flows = data.map((item) => {
                return {
                    source: item['key'].split('-')[0],
                    target: item['key'].split('-')[1],
                    value: item['total']['value'],
                    text: item['value'],
                };
            });
            let clients = Array.from(new Set(flows.map((item) => item['source'])));
            let servers = Array.from(new Set(flows.map((item) => item['target'])));

            let combinedArray = [...clients, ...servers];
            let uniqueSets = new Set(combinedArray);
            let temp = [...uniqueSets].map((label, index) => ({ label, index }));
            let randomColors = this.generateRandomColors(temp.length);
            let i = 0;
            let j = 0;
            let size = this.colors.length;
            let nodes = temp.map((item) => {
                let color = '';
                if (j < size) {
                    color = this.colors[j++];
                } else {
                    color = randomColors[i++];
                }
                return { ...item, color: color };
            });

            keys = data.map((item) => item['key']);

            this.topDeviceData = [
                {
                    type: 'sankey',
                    orientation: 'h',
                    node: {
                        pad: 10,
                        thickness: 10,
                        line: {
                            color: 'black',
                            width: 0.5,
                        },
                        label: nodes.map((node) => node.label),
                        color: nodes.map((node) => node.color),
                        style: {},
                        hovertemplate: '<extra></extra>',
                    },
                    link: {
                        arrowlen: 3,
                        source: flows.map((link) => nodes.find((node) => node.label === link['source']).index),
                        target: flows.map((link) => nodes.find((node) => node.label === link['target']).index),
                        value: flows.map((link) => link['value']),
                        customdata: flows.map((link) => link['text']),
                        color: flows.map((link) => '#c4c4ca'),
                        hovertemplate: '%{source.label} -> %{target.label} - %{customdata} <extra></extra>',
                    },
                },
            ];
            this.loading = false;
            this.topDeviceLayout = {
                font: {
                    size: 17,
                    color: 'black',
                },
            };
            this.topDeviceConf = {
                displayModeBar: false,
            };
        } else {
            this.loading = false;
            let keys = [];
            let values = [];
            const tooltips = [];
            if (data[0].device_name) {
                keys = data.map((inp) => inp.device_name);
            } else {
                keys = data.map((inp) => inp.key);
            }
            values = data.map((inp) => inp['total'].value / 1000000);

            const total = values.reduce((val, acu) => val + acu);
            for (const i in data) {
                tooltips[i] = data[i].device_name + ' - ' + data[i].value;
            }
            for (const i in keys) {
                if (keys[i].includes('ORF')) {
                    keys[i] = keys[i].split('_');
                    keys[i].splice(0, 1);
                    keys[i] = keys[i].join('_');
                }
            }
            if (this.myChart) {
                this.myChart.destroy(); // to destroy previously created chart
            }
            this.canvas = document.getElementById(htmlId);
            this.ctx = this.canvas?.getContext('2d');
            this.myChart = new Chart(this.ctx, {
                type: 'horizontalBar',
                data: {
                    labels: keys,
                    datasets: [
                        {
                            label: 'Usage in MB',
                            data: values,
                            backgroundColor: this.colors,
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            fontSize: 12,
                        },
                        // @ts-ignore
                        onClick: (e, legendItem, legend) => {
                            // Prevent the default action of hiding the dataset
                            e.stopPropagation();
                        },
                    },
                    scales: {
                        // @ts-ignore
                        y: [
                            {
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Usage in MB', // Your X-axis title here
                                },
                            },
                        ],
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItems) => {
                                return tooltips[tooltipItems.index];
                            },
                        },
                        titleFontSize: 0,
                        bodyFontSize: 12,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
        }
    }

    getTopSources() {
        this.loading = true;

        this.service.getTopTenantSources(this.graphConfData).subscribe((data: any) => {
            this.loading = false;
            if (data.length < 1) {
                this.isEmpty = true;
            } else {
                this.isEmpty = false;
                setTimeout(() => {
                    this.renderTopSources(data, 'tenantGraph');
                }, this.renderTime);
            }
        }),
            (err) => {
                this.loading = false;
                console.log(err);
            };
    }

    renderTopSources(data: Array<any>, htmlId) {
        let keys = [];
        let values = [];
        const tooltips = [];
        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });
        keys = data.map((inp) => inp.key);
        values = data.map((inp) => inp['total'].value / 1000000);

        for (const i in data) {
            tooltips[i] = data[i].key + ' - ' + data[i].value;
        }
        if (this.myChart) {
            this.myChart.destroy(); // to destroy previously created chart
        }
        this.canvas = document.getElementById(htmlId);
        this.ctx = this.canvas?.getContext('2d');

        //@ts-ignore
        this.myChart = new Chart(this.ctx, {
            type: 'horizontalBar',
            data: {
                labels: keys,
                datasets: [
                    {
                        label: 'Usage in MB',
                        data: values,
                        backgroundColor: [
                            '#0078be','#00b3f0','#6801af','#bb64ff','#ff560b','#ffa608','#298c00','#a9ce21','#58595b','#939598'
                            ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                legend: {
                    position: 'bottom',
                    labels: {
                        fontSize: 12,
                    },
                    // @ts-ignore
                    onClick: (e, legendItem, legend) => {
                        // Prevent the default action of hiding the dataset
                        e.stopPropagation();
                    },
                },
                scales: {
                    // @ts-ignore
                    y: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Usage in MB', // Your X-axis title here
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItems) => {
                            return tooltips[tooltipItems.index];
                        },
                    },
                    titleFontSize: 0,
                    bodyFontSize: 12,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    }

    getTopDestinations() {
        this.loading = true;
        this.service.getTopDestinationsData(this.graphConfData).subscribe((data: any) => {
            this.loading = false;

            if (data.length < 1) {
                this.isEmpty = true;
            } else {
                this.isEmpty = false;
                setTimeout(() => {
                    this.renderTopDestinations(data, 'tenantGraph');
                }, this.renderTime);
            }
        }),
            (err) => {
                this.loading = false;
                console.log(err);
            };
    }

    renderTopDestinations(data: Array<any>, htmlId) {
        let keys = [];
        let values = [];
        const tooltips = [];

        data.sort(function (a, b) {
            return b['total']['value'] - a['total']['value'];
        });

        keys = data.map((inp) => {
            let key = inp['key'];
            if (inp['address'] != 'unknown') {
                if (inp['address'].includes('googleusercontent')) {
                    key = 'googledrive';
                } else if (inp['address'].includes('cloudfront')) {
                    key = 'cloudfront.net';
                } else if (inp['address'].includes('centos')) {
                    key = 'centos.org';
                } else {
                    key = inp['address'];
                }
                // let arr = inp['address'].split('-').reverse();
                // console.log('Array - ', arr);
                // if (arr.length <= 1) {
                //     arr = inp['address'].split('.').reverse();
                //     console.log('Array .', arr);
                //     key = arr.length >= 2 ? arr[1] + '.' + arr[0] : inp['key'];
                // } else {
                //     key = arr[0];
                // }
            }
            return key;
        });

        values = data.map((inp) => inp['total'].value / 1000000);

        for (const i in data) {
            tooltips[i] = data[i].key + ' - ' + data[i].value;
        }
        if (this.myChart) {
            this.myChart.destroy(); // to destroy previously created chart
        }
        this.canvas = document.getElementById(htmlId);
        this.ctx = this.canvas?.getContext('2d');

        //@ts-ignore
        this.myChart = new Chart(this.ctx, {
            type: 'horizontalBar',
            data: {
                labels: keys,
                datasets: [
                    {
                        label: 'Usage in MB',
                        data: values,
                        backgroundColor: [
                            '#0078be','#00b3f0','#6801af','#bb64ff','#ff560b','#ffa608','#298c00','#a9ce21','#58595b','#939598'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                legend: {
                    position: 'bottom',
                    labels: {
                        fontSize: 12,
                    },
                    // @ts-ignore
                    onClick: (e, legendItem, legend) => {
                        // Prevent the default action of hiding the dataset
                        e.stopPropagation();
                    },
                },
                scales: {
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                // labelString: 'Usage in MB',
                            },
                            ticks: {
                                callback: function(value: string) {
                                    const length = value.length
                                    if (length > 20) {
                                        const label = value.split('.')
                                        const labelLength = label.length
                                        return label[labelLength - 2] + '.' + label[labelLength - 1]
                                    }
                                    else {
                                        return value
                                    }
                                }
                            }
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.xLabel + ' MB';
                        }
                    },
                    titleFontSize: 0,
                    bodyFontSize: 12,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
        
    }

    generateRandomColors(n: number): string[] {
        const colors = [];
        for (let i = 0; i < n; i++) {
            let color: string;
            do {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                color = `rgb(${r}, ${g}, ${b})`;
            } while (colors.findIndex((obj) => obj == color) != -1);
            colors.push(color);
        }
        return colors;
    }
}
