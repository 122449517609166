<div *ngIf="dataLength === 0 && !loading">
    <div class="noDataContainer" [ngSwitch]="type">
        <span *ngSwitchCase="'configuration'">
            <img [class]="type" src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noConf.png" />
            <p>
                {{ 'Create your First Configuration' }}
            </p>
        </span>
        <span *ngSwitchCase="'status'">
            <img [class]="type" src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noStatus.png" />
            <p>
                {{ 'No Status Available' }}
            </p>
        </span>
        <span *ngSwitchCase="'networks'">
            <img [class]="type" src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noNetworks.png" />
            <p>
                {{ 'Create your First Network' }}
            </p>
        </span>
        <span *ngSwitchCase="'user'">
            <img [class]="type" src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noUser.png" />
            <p>
                {{ 'Create your First User' }}
            </p>
        </span>
        <span *ngSwitchCase="'configTemp'">
            <img [class]="type" src="https://cdn.cosgrid.com/website/COSGridNet/assets/noData/noConfigTemplates.png" />
            <p>
                {{ 'Create your First Config Template' }}
            </p>
        </span>
    </div>
</div>
