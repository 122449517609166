import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cosgrid-bar',
    templateUrl: './bar.component.html',
    styleUrls: ['./bar.component.scss'],
})
export class BarComponent implements OnInit {
    @Input() graph = { data: [], layout: { title: '', width: 400, height: 300, autosize: true } };
    constructor() {}

    ngOnInit() {}
}
