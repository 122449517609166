import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/configs/constants';
import { SDWanGatewayUtilService } from 'app/modules/sd-wan-gateway/common/services/sd-wan-gateway.util.service';

// return this.httputil.get(url)
// .map((response: any) => {
//   return JSON.parse(response._body);

// })

@Injectable({
    providedIn: 'root',
})
export class ChartserviceService {
    constructor(
        private http: HttpClient,
        private globalconstant: GlobalConstants,
        private sdWanGatewayUtilService: SDWanGatewayUtilService,
    ) {}

    getTopProtocolsData(data) {
        return this.http.post(
            this.globalconstant.status_netflow +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalconstant.STATUS_TOP_PROTO,
            data,
        );
    }

    getTopDestinationsData(data) {
        return this.http.post(this.globalconstant.status_netflow + this.globalconstant.STATUS_TOP_DEST, data);
    }
    getTopClientPortsData(data) {
        return this.http.post(
            this.globalconstant.status_netflow +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalconstant.STATUS_TOP_CLIENT,
            data,
        );
    }

    getTopServerPortsData(data) {
        return this.http.post(
            this.globalconstant.status_netflow +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalconstant.STATUS_TOP_SERVER,
            data,
        );
    }
    getTopTalkersData(data) {
        return this.http.post(
            this.globalconstant.status_netflow +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalconstant.STATUS_TOP_TALKERS,
            data,
        );
    }

    // TENANT LEVEL

    getTopDevicesData(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_DEVICES,
            data,
        );
    }

    getTopTenantProto(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_PROTO,
            data,
        );
    }
    getTopTenantApplications(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_APPLICATION,
            data,
        );
    }

    getTopTenantUsers(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_USERS,
            data,
        );
    }

    getTopTenantSources(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_SOURCES,
            data,
        );
    }
    getTopTenantDestinationData(data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_DESTINATION,
            data,
        );
    }

    getNetflowGraphs(url, data) {
        return this.http.post(
            this.globalconstant.status_netflow + this.sdWanGatewayUtilService.getDeviceId() + '/' + url,
            data,
        );
    }

    getIndividualUserData(data) {
        return this.http.post(
            this.globalconstant.status_netflow +
                this.sdWanGatewayUtilService.getDeviceId() +
                this.globalconstant.STATUS_TRAFFIC_TOP_TENANT_INDIVIDUAL_USER_DATA,
            data,
        );
    }

    // getTopTalkersData(): Observable<any> {
    //   const topTalkers = this.globalconstant.status_traffic + this.sdWanGatewayUtilService.getDeviceId() + this.globalconstant.STATUS_TRAFFIC_TOP_TALKERS
    //   return this.httputil.post(topTalkers, {})

    // }

    // getTopServerPortsData(): Observable<any> {
    //   const topServerPorts = this.globalconstant.status_traffic + this.sdWanGatewayUtilService.getDeviceId() + this.globalconstant.STATUS_TRAFFIC_TOP_SERVER_PORTS
    //   return this.httputil.post(topServerPorts, {})

    // }

    // getTopClientPortsData(): Observable<any> {
    //   const topClientPorts = this.globalconstant.status_traffic + this.sdWanGatewayUtilService.getDeviceId() + this.globalconstant.STATUS_TRAFFIC_TOP_CLIENT_PORTS
    //   return this.httputil.post(topClientPorts, {})

    // }

    // getTopProtocolsData(): Observable<any> {
    //   const topProtocols =  this.globalconstant.status_traffic + this.sdWanGatewayUtilService.getDeviceId() + this.globalconstant.STATUS_TRAFFIC_TOP_PROTOCOLS
    //   return this.httputil.post(topProtocols, {})

    // }

    // getTopDestinationsData(): Observable<any> {
    //   const topDestinations = this.globalconstant.status_traffic + this.sdWanGatewayUtilService.getDeviceId() + this.globalconstant.STATUS_TRAFFIC_TOP_DESTINATIONS
    //   return this.httputil.post(topDestinations, {})

    // }
}
