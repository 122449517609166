<style>
    .client-server {
        background-color: #fff;
        /* box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5); */
        padding: 3px 6px 3px 6px;
    }

    .graphContainer {
        height: 260px !important;
        margin-top: 5px;
    }

    #tenantGraph {
        margin: auto;
        margin-top: 3px;
        height: 260px !important;
        width: 100% !important;
    }

    p {
        text-align: center;
        margin: 5vh !important;
        height: 100%
    }
</style>


<div class="client-server">
    <div class="d-flex justify-content-between">
        <select (change)="changeChart()" [(ngModel)]="currentChartId" class="form-control form-control-sm col-3">
            <option value=1>Top Sites</option>
            <option value=2>Top Protocols</option>
            <option value=3>Top Applications</option>
            <option value=4>Top Talkers</option>
            <option value=5>Top Sources</option>
            <option value=6>Top Destinations</option>
        </select>
        <div *ngIf="loading" class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <select (change)="changeChart()" [(ngModel)]="graphConfData.timeduration"
            class="form-control form-control-sm col-2">
            <option value="60">Last 1 hr</option>
            <option value="1440">Last 24 hrs</option>
            <option value="10080">Last 1 week</option>
            <!-- <option value="43200">Last 1 month</option> -->
        </select>
    </div>
    <div *ngIf="isEmpty" style="margin: auto; height: 12vh;">
        <p>
            No data available yet!
        </p>
    </div>
    <div *ngIf="!isEmpty && !loading && currentChartId == 4">
      <div class="graphContainer">
        <div id="talkers-container" style="height: 300px;"></div>
        <!-- <plotly-plot [data]="topDeviceData" [layout]="topDeviceLayout" [config]="topDeviceConf" [style]="{'height':'300px'}"  ></plotly-plot> -->
      </div>
    </div>

    <div class="graphContainer" *ngIf="!isEmpty && currentChartId != 4">
        <canvas id="tenantGraph"></canvas>
    </div>
</div>
