<div class="tableHeading">
  <h3>WAN Alarms</h3>
</div>
<div class="float-right" >
    <button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="filterMenu" class="btn btn-light">
      <i  class="fas fa-filter"></i>
        {{selectedFilter}}
    </button>
    <mat-menu #filterMenu >
     <button mat-menu-item
     (click)="filterData('All')"
      >All
    </button>
     <button *ngFor="let item of button"  mat-menu-item
     (click)="filterData(item)"
      >{{item}}
    </button>
    </mat-menu>
</div>
  <cosgrid-table
    [nullMessage]="'No WAN Reachability History available yet!'"
    [rows]="filteredRows"
    [rowProps]="rowProps"
    [tableProps]="tableProps"
    [isConfig]="false"
    [refreshLoading]="loading"
    (refreshed)="getAlerts()"
    [legends]="legends"
></cosgrid-table>