<div id="map" style="padding: 3px; margin-top: 10px;" class="card" [ngStyle]="{'height':vpnDashboard && '448px'}">
    <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoomControl]="true"
        [maxZoom]="20"
        [streetViewControl]="false"
        [mapDraggable]="true"
        [fitBounds]="latlngBounds"
        [fitBoundsPadding]="0"
        [scrollwheel]="false"
        [mapTypeId]="'roadmap'"
        [ngStyle]="{'height':vpnDashboard ? '445px' : '350px'}"
    >
        <agm-marker
            (mouseOver)="onMouseOver(infoWindow, $event)"
            (mouseOut)="onMouseOut(infoWindow, $event)"
            *ngFor="let l of latlongs"
            [openInfoWindow]=""
            [latitude]="l.latt"
            [longitude]="l.long"
            [iconUrl]="l.icon"
        >
            <agm-info-window [disableAutoPan]="true" #infoWindow>
                <div style="margin: 0px;">
                    <div>
                        <h6 [ngStyle]="{ color: l.deviceInfo.status === 'running' ? '#218838' : '#dc3545' }">
                            <strong>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </strong>{{ l.deviceInfo.name }}
                        </h6>
                        <!-- <h6 [ngStyle]="{'color':l.deviceInfo.status === 'running' ? '#218838' : '#dc3545' }"><strong>IP
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
              </strong>{{l.deviceInfo.lastIPAddress}}</h6> -->
                        <h6 [ngStyle]="{ color: l.deviceInfo.status === 'running' ? '#218838' : '#dc3545' }">
                            <strong>Location : </strong>{{ l.label }}
                        </h6>
                    </div>
                </div>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>
