<style>
    .overflow {
        max-height: 500px;
        overflow-y: scroll;
    }

    #search {
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 400;
        font-size: 14px;
        text-decoration: inherit;
    }

    #dropdownButton {
        border: 0px solid !important;
        background-color: white;
        padding: 5px;
        margin-right: 12px;
    }

    .textButton {
        border: 0px solid !important;
        background-color: white !important;
        padding: 1px;
        margin-left: 3px;

    }

    .btn-group {
        border: 1px solid lightgray !important;
        border-radius: 5px !important;
        background-color: white;
        height: 25px;
        margin-top: 1.2px;
    }

    input {
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
    }

    i {
        font-weight: 18px !important;
    }

    .dropdown-item:hover {
        background-color: rgb(220, 220, 220);
    }

    /* @media screen and (max-width: 1200px) {
        .btn-group {
            height: auto !important;
        }
    } */
</style>
<div class="btn-group">
    <button class="textButton" type="button" id="selectdevice" data-container="body" data-toggle="popover"
        title="Select Device" data-placement="bottom" data-trigger="focus" tabindex="0"
        data-content="select device to configure and view status">
        {{selectedDeviceName | truncate}}
        <!-- <span class="sr-only"></span> -->
    </button>

    <button type="button" id="dropdownButton" class="dropdown-toggle dropdown-toggle-split clickable"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only"></span>
    </button>
    <div style="min-width: 200px;" class="dropdown-menu dropdown-menu-right overflow DropdownAnimate slideIn">
        <input id="search" autocomplete="off" placeholder="&#xf002;" class="form-control form-control-sm"
            (keyup)="filterValue($event)" type="text" />

        <a style="width: 80%; padding: 10px 15px; margin-right: 0px; cursor: pointer;" *ngFor="let device of deviceList"
            (click)="devChanged(device)" class="dropdown-item">
            {{ device?.name || '' }}
            <i matTooltip="Running" matTooltipPosition="right"
                *ngIf="device.deviceStatus === 'running' || device.deviceStatus ===  'connected'"
                style="float: right; color: lawngreen;" class="fas fa-sort-up"></i>
            <i matTooltip="Not Reachable" matTooltipPosition="right"
                *ngIf="device.deviceStatus === 'error' || device.deviceStatus === 'offline'"
                style="float: right; color: orangered;" class="fas fa-sort-down"></i>
            <i matTooltip="Registered" matTooltipPosition="right" *ngIf="device.deviceStatus === 'registered'"
                style="float: right; color: orange;" class="fas fa-check"></i>
        </a>
    </div>
</div>