<div class="wrapper">

  <div class="card datatable-card" style="width: 500px;margin: auto">
    <cosgrid-configuration-header [title]="'Ticket Raise'" [content]="'<h5> Raise a Ticket to customer support  </h5>
  <hr>
  <p></p>'">
    </cosgrid-configuration-header>
    <div class="data-table-wrapper">
      <form [formGroup]="ticketRaiseForm" class="ml-2">
        <div class="form-row">
          <div class="form-group col-md-6">
            <config-select name="Issue in" formControlName="issue_in" [options]="devices" viewKey="name"
              valueKey="name"></config-select>
            <!-- <label>Issue in :
            </label>
            <select class="form-control form-control-sm col-md-10" formControlName="issue_in">
              <option value="common">common</option>
              <option *ngFor="let item of devices" [value]="item.name">{{item.name}}</option>
            </select> -->
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <config-input name="Issue" type="string" formControlName="issue_sub"
              placeholder="Issue Name"></config-input>
            <!-- <label class="required">Issue
            </label>
            <input formControlName="issue_sub" type="text" class="form-control form-control-md col-md-10"
              [ngClass]="{'is-valid': !ticketRaiseForm.controls.issue_sub.invalid && ticketRaiseForm.controls.issue_sub.touched ,'is-invalid':ticketRaiseForm.controls.issue_sub.invalid && ticketRaiseForm.controls.issue_sub.touched}"
              placeholder="Issue Name"> -->
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-8">
            <label>Description
            </label>
            <textarea formControlName="issue_desc" rows=5 class="form-control form-control-md "
              placeholder="Detailed Description of the issue"></textarea>

          </div>
        </div>

        <div class="row" style="margin-top: 10px">
          <div class="col">
            <button (click)="submitForm()" class="btn btn-outline-primary" style="float:right;">
              Submit <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>