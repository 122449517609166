import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'rum',
    templateUrl: './rum.component.html',
    styleUrls: ['./rum.component.scss'],
})
export class RUMComponent implements OnInit {
    constructor(private sanitizer: DomSanitizer) {}

    counter: number = 0;
    cpugraph: any;
    api = 'http://15.207.116.241:3003';
    ngOnInit() {
        // this.cpugraph    = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.api}/dashboard-solo/db/${this.devicename}-metrics?orgId=1&refresh=5s&panelId=1`)
        this.cpugraph = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.api}/d/eX34qINMk/murugavels-dashboard?orgId=1&refresh=5s&panelId=1`,
        );
    }

    clickeditem(temp: number) {
        this.counter = temp;
    }

    checkcounter(temp: number) {
        if (this.counter === temp) {
            return true;
        }

        return false;
    }
}
