<div class="wrapper">
    <cosgrid-configuration-header
        [title]="'Ticket History'"
        (reload)="getIssues()"
        [content]="
            '<h5>Ticket History </h5>
              <hr>
                  <p>View All your Ticket History
                  </p>
              <hr>'
        "
    >
    </cosgrid-configuration-header>
    <div class="card datatable-card">
        <br />
        <div class="data-table-wrapper">
            <cosgrid-overlay-loader style="height: 75px; text-align: center;" *ngIf="loading"></cosgrid-overlay-loader>

            <div *ngIf="!loading" class="table-responsive" style="overflow-x: hidden;">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Issue in</th>
                            <th scope="col">Issue</th>
                            <th scope="col">Description</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of issuesData">
                            <td>{{ item.issue_in }}</td>
                            <td>{{ item.issue_sub }}</td>
                            <td>{{ item.issue_desc }}</td>
                            <td>
                                <span
                                    id="status"
                                    [ngStyle]="{ 'background-color': item.status === 'OPEN' ? '#C82333' : '#218838' }"
                                >
                                    {{ item.status }}
                                    <i *ngIf="item.status === 'CLOSE'" class="far fa-check-circle"></i>
                                    <i *ngIf="item.status === 'OPEN'" class="fas fa-exclamation-circle"></i>
                                </span>
                            </td>
                            <!-- <td *ngIf="isSuperAdmin" >
                              <mat-slide-toggle  class="toggle" (change)="onoff(item)"   [checked]="item.enabled" matTooltip="Change Status" >
                              </mat-slide-toggle>
                          </td> -->
                            <td>
                                <i
                                    (click)="openDialog(item)"
                                    *ngIf="item.status === 'OPEN'"
                                    class="fa fa-pencil-square-o"
                                ></i>
                                <i
                                    (click)="openDialog(item)"
                                    style="color: green;"
                                    *ngIf="item.status === 'CLOSE'"
                                    class="fas fa-check-circle"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <p *ngIf="issuesData.length < 1 "> You Have not raised any tickets </p> -->
        </div>
    </div>
</div>
